import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import "assets/css/global.css";
import "assets/css/stayc_fullpage.css";
import "assets/css/stayc_photoswipe.css";
//import "assets/css/stayc_slick.css";
import "assets/css/stayc_style.css";
import "assets/css/stayc_style_162.css";
import "assets/css/stayc_fullpage.css";
import "assets/css/stayc_other.css";

//import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "views/pages/Home.js";
import Login from "views/pages/Login.js";
import SocialMedia from "views/pages/SocialMedia.js";
import Logout from "views/pages/Logout.js";
import ImageSearch from "views/pages/ImageSearch.js";
import ImageBrowser from "views/pages/ImageBrowser.js";
import ImageExport from "views/pages/ImageExport.js";
import Archive from "views/pages/Archive.js";
import YT from "views/pages/YT.js";
import Wallpapers from "views/pages/Wallpapers.js";
import J from "views/pages/J.js";
import ISA from "views/pages/ISA.js";
import IveMemes from "views/pages/IveMemes.js";

import Cookies from "js-cookie";
import LogRocket from "logrocket";
LogRocket.init('staycswith-zbyke/staycswith');
if (Cookies.get("user_id")) {
  LogRocket.identify(Cookies.get("user_id"), {
    username: Cookies.get("username"),
  });
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Home {...props} />} />

      <Route path="/login" exact render={(props) => <Login {...props} />} />
      <Route
        path="/socialmedia"
        exact
        render={(props) => <SocialMedia {...props} />}
      />
      <Route path="/logout" exact render={(props) => <Logout {...props} />} />
      <Route path="/img" exact render={(props) => <ImageSearch {...props} />} />
      {/*<Route
        path="/browse"
        exact
        render={(props) => <ImageBrowser {...props} />}
      />*/}
      <Route path="/export" exact render={(props) => <ImageExport {...props} />} />
      <Route path="/archive/:group/:member" render={(props) => <Archive {...props} />} />
      <Route path="/archive/:group" render={(props) => <Archive {...props} />} />
      <Route path="/archive" exact render={(props) => <Archive {...props} />} />
      <Route path="/batch" exact render={(props) => <Archive {...props} />} />
      <Route path="/yt" exact render={(props) => <YT {...props} />} />
      <Route
        path="/wallpapers"
        exact
        render={(props) => <Wallpapers {...props} />}
      />
      <Route path="/j" exact render={(props) => <J {...props} />} />
      <Route path="/isa" exact render={(props) => <ISA {...props} />} />
      <Route path="/ivememes" exact render={(props) => <IveMemes {...props} />} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
