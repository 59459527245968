import React from "react";

import "../../assets/css/global.css";

import { Container } from "reactstrap";
import SimpleFooter from "components/Footers/SimpleFooter.js";

import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

import { isa_bunny_selcas } from "../../assets/isa_bunny_selcas.js";

import { Masonry } from "masonic";

const MasonryItem = ({ data: { w }, width }) => {
  return (
      <img
        style={{
          width: width,
        }}
        src={w}
        key={w}
        alt={w}
      ></img>
  );
};

class ISA extends React.Component {
  render() {
    const selcas = isa_bunny_selcas.map((w) => {
      return { w: w };
    });

    return (
      <>
        <SimpleNavbar />
        <main ref="main">
          <div className="position-relative">
            <section
              className="section"
              style={{
                minHeight: "100vh",
              }}
            >
              <Container>
                <h2 className="mb-3" style={{ color: "white" }}>
                  ISA Bunny Selcas
                </h2>
                <Masonry
                  key={selcas}
                  columnCount={3}
                  overscanBy={Infinity}
                  items={selcas}
                  render={MasonryItem}
                ></Masonry>
              </Container>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ISA;
