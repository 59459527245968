import React from "react";

import "../../assets/css/global.css";

import { Container } from "reactstrap";
import SimpleFooter from "components/Footers/SimpleFooter.js";

import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

import { ive_memes } from "../../assets/ive_memes.js";

import { LazyLoadComponent } from "react-lazy-load-image-component";

class IveMemes extends React.Component {
  constructor(props) {
    super(props);

    this.state = { width: 0, height: 0 };
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (
      this.state.height > this.state.width !==
      window.innerHeight > window.innerWidth
    ) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    const memes = ive_memes.map((m) => {
      if (m.type == "image") {
        return (
          <div
            style={{
              width: "25vw",
              minWidth: "200px",
              maxWidth: "95vw",
              margin: "10px",
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <LazyLoadComponent>
              <h6 style={{ color: "white" }}>{m.title}</h6>
              <img
                style={{
                  width: "25vw",
                  minWidth: "200px",
                  maxWidth: "95vw",
                  margin: "0px",
                }}
                src={m.url}
                key={m.url}
                alt={m.title}
              ></img>
            </LazyLoadComponent>
          </div>
        );
      } else {
        return (
          <div
            style={{
              width: "25vw",
              minWidth: "200px",
              maxWidth: "95vw",
              margin: "10px",
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <LazyLoadComponent>
              <h6 style={{ color: "white" }}>{m.title}</h6>
              <video
                style={{
                  width: "25vw",
                  minWidth: "200px",
                  maxWidth: "95vw",
                  margin: "0px",
                }}
                src={m.url}
                key={m.url}
                alt={m.title}
                controls
              ></video>
            </LazyLoadComponent>
          </div>
        );
      }
    });
    return (
      <>
        <SimpleNavbar />
        <main ref="main">
          <div className="position-relative">
            <section
              className="section"
              style={{
                minHeight: "100vh",
              }}
            >
              <Container
                style={{
                  maxWidth: this.state.width > 1000 ? "80vw" : "100vw",
                }}
              >
                <h2 className="mb-3" style={{ color: "white" }}>
                  IVE Memes
                </h2>
                <div>{memes}</div>
              </Container>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default IveMemes;
