import React from "react";

// reactstrap components
import { Button } from "reactstrap";

import MediaQuery from "react-responsive";
import "assets/css/global.css";
// core components
import SimpleFooter from "components/Footers/SimpleFooter.js";

import { Form } from "react-bootstrap";

import CircularProgress from "react-cssfx-loading/lib/CircularProgress";

import Dropzone from "react-dropzone";

import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

const domain =
  process.env.REACT_APP_REL === "TRUE" ? "" : "https://staycswith.com";

class ImageSearch extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      displayButton: false,
      isDisable: false,
      done: false,
      group: "any",
      prediction: [],
      image2: "",
      first: true,
      clicked: false,
      link: "",
      source: "",
    };
  }

  componentDidMount() {
    if (this.state.first) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.setState({
        first: false,
      });
    }
  }

  onDrop = (media) => {
    if (media.length > 0) {
      const file = media[0];
      if (
        !file.name.includes("/") &&
        (file.type.includes("image") || file.type.includes("video"))
      ) {
        this.setState({
          img: URL.createObjectURL(file),
          image: file,
          displayButton: true,
          isDisable: false,
          done: false,
          attachmentURL: [],
          image2: "",
          link: "",
        });
      }
    }
  };

  linkChange = (event) => {
    const value = event.target.value;
    this.setState({
      link: value,
      displayButton: true,
      isDisable: false,
      done: false,
      attachmentURL: [],
      img: null,
      image: null,
      image2: "",
    });
  };

  groupChange = (event) => {
    this.setState({
      group: event.target.value,
    });
  };

  click = () => {
    this.setState({
      isDisable: true,
      attachmentURL: null,
      scores: null,
    });
    var formdata = new FormData();
    formdata.append("file", this.state.image);
    formdata.append("group", this.state.group);
    formdata.append("link", this.state.link);
    fetch(domain + "/query", {
      method: "POST",
      body: formdata,
    })
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        } else {
          return response.json();
        }
      })
      .then((result) => {
        this.setState({
          attachmentURL: result.attachmentURL,
          messageURL: result.messageURL,
          scores: result.scores,
          prediction: result.prediction,
          image2: result.image,
          source: result.source,
          displayButton: false,
          done: true,
        });
        this.scrollToBottom();
      })
      .catch((error) => {
        this.setState({
          error: true,
          isDisable: false,
        });
      });
  };

  scrollToBottom = () => {
    this.myRef.current.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  };

  setClicked(clicked) {
    this.setState({ clicked: clicked });
  }

  render() {
    let results = "";

    if (this.state.attachmentURL != null) {
      results = this.state.attachmentURL
        .map((url, i) => {
          if (url !== "") {
            return (
              <figure
                style={{
                  float: "left",
                  marginRight: "20px",
                  marginBottom: "20px",
                }}
              >
                {url.endsWith(".mp4") ||
                url.endsWith(".mpeg") ||
                url.endsWith(".mov") ||
                url.endsWith(".webm") ? (
                  <>
                    <MediaQuery minDeviceWidth={1224}>
                      <a
                        href={this.state.messageURL[i]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <video
                          src={this.state.attachmentURL[i]}
                          height={"200px"}
                          alt="matched"
                          playsInline
                          autoPlay
                          muted
                          loop
                        />
                      </a>
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={1224}>
                      <video
                        src={this.state.attachmentURL[i]}
                        height={"200px"}
                        alt="matched"
                        playsInline
                        autoPlay
                        muted
                        loop
                      />
                    </MediaQuery>
                  </>
                ) : (
                  <>
                    <MediaQuery minDeviceWidth={1224}>
                      <a
                        href={this.state.messageURL[i]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={
                            (this.state.attachmentURL[i].match("^http:")
                              ? "https://stayc.onrender.com/"
                              : "") + this.state.attachmentURL[i]
                          }
                          height={"200px"}
                          alt="matched"
                        />
                      </a>
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={1224}>
                      <a
                        href={this.state.messageURL[i]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={
                            (this.state.attachmentURL[i].match("^http:")
                              ? "https://stayc.onrender.com/"
                              : "") + this.state.attachmentURL[i]
                          }
                          height={"200px"}
                          alt="matched"
                        />
                      </a>
                    </MediaQuery>
                  </>
                )}
                <figcaption>{this.state.scores[i]}</figcaption>
              </figure>
            );
          }
          return "";
        })
        .filter((el) => el !== "");
    }

    /*const prediction =
      this.state.prediction.length > 0 ? (
        <div>
          Prediction:{" "}
          <b>
            {this.state.prediction[0].group} {this.state.prediction[0].name}
          </b>
          {this.state.prediction.length > 1 ? (
            <div style={{ display: "inline" }}>
              {this.state.prediction.slice(1).map((pred, i) => {
                return (
                  <b>
                    , {pred.group} {pred.name}
                  </b>
                );
              })}
            </div>
          ) : (
            ""
          )}
          <br />
        </div>
      ) : (
        ""
      );*/

    const source =
      this.state.source !== "" ? (
        <div>
          Source:{" "}
          <b>
            {this.state.source.date} {this.state.source.media_type}:{" "}
            <a target="_blank" rel="noreferrer" href={this.state.source.url}>
              {this.state.source.url}
            </a>
          </b>
        </div>
      ) : (
        ""
      );

    const image2 = "data:image/jpeg;base64," + this.state.image2;
    return (
      <>
        <SimpleNavbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section" style={{ minHeight: "100vh" }}>
              <div className="container">
                <h2 style={{ color: "white" }}>Reverse Image Search</h2>
                <Form className="mt-2">
                  <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label>
                      {this.state.done ? (
                        "Results below. Choose another photo to search again."
                      ) : this.state.displayButton ? (
                        "Refine your search with the dropdown or click submit."
                      ) : (
                        <b>STAYC and NMIXX only</b>
                      )}
                    </Form.Label>
                    <Dropzone
                      onDrop={this.onDrop}
                      accept="image/*"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps({ className: "dropzone" })}
                            style={{
                              borderColor: this.state.clicked
                                ? "#2990ff"
                                : "#eeeeee",
                            }}
                            disabled={this.state.uploading}
                            onMouseEnter={() => this.setClicked(true)}
                            onMouseLeave={() => this.setClicked(false)}
                          >
                            <input {...getInputProps()} />
                            <br />
                            <p>Drop an image here, or click to select</p>
                            <br />
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <Form.Group className="mt-2">
                      <Form.Label>Image URL</Form.Label>
                      <Form.Control
                        value={this.state.link}
                        onChange={this.linkChange}
                        name={"link"}
                      ></Form.Control>
                    </Form.Group>
                  </Form.Group>
                </Form>
                {this.state.displayButton ? (
                  <Button
                    className="btn-icon img-fluid mb-1"
                    color="primary"
                    onClick={this.click}
                    disabled={this.state.isDisable}
                  >
                    {this.state.isDisable ? (
                      <div>
                        Please Wait{" "}
                        <CircularProgress height="20px" color="#FFFFFF" />
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                ) : (
                  ""
                )}
                {this.state.img == null && this.state.link === "" ? (
                  ""
                ) : (
                  <div>
                    <h2>Query:</h2>
                    <img
                      src={
                        this.state.image2 === ""
                          ? this.state.img ||
                            "https://stayc.onrender.com/" +
                              this.state.link
                          : image2
                      }
                      style={{ maxHeight: "50vh", maxWidth: "100vw" }}
                      alt="uploaded query"
                    />
                    <br />
                    <br />
                  </div>
                )}
                {this.state.done ? (
                  <div>
                    <h2 ref={this.myRef}>Results:</h2>
                    Click on the images to see the original message and date.
                    <br />
                    {source}
                    <br />
                  </div>
                ) : (
                  ""
                )}
                {results}
              </div>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ImageSearch;
