import React from "react";

// reactstrap components
import { Button, Container, Col } from "reactstrap";

import Cookies from "js-cookie";

import "../../assets/css/global.css";
// core components
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { Form } from "react-bootstrap";
import CircularProgress from "react-cssfx-loading/lib/CircularProgress";
import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

const groups = {
  stayc: ["group", "sumin", "sieun", "isa", "seeun", "yoon", "j"],
  ive: ["group", "gaeul", "yujin", "rei", "wonyoung", "liz", "leeseo"],
  nmixx: [
    "group",
    "lily",
    "haewon",
    "sullyoon",
    "jinni",
    "bae",
    "jiwoo",
    "kyujin",
  ],
  viviz: ["group", "eunha", "sinb", "umji"],
};

const group_options = Object.keys(groups).map((group, i) => {
  return <option key={group}>{group}</option>;
});

const domain =
  process.env.REACT_APP_REL === "TRUE" ? "" : "https://staycswith.com";
  
/// TODO:
/// ADD DATE RANGE, MEDIA TYPE, SOURCE USERNAME
/// DATABASE IN BACKEND TO KEEP TRACK OF EXPORTS AND PROGRESS
/// USE BOX API
/// EXPORT TO GOOGLE DRIVE THEN ON HEROKU EXPORT TO BOX


class ImageExport extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isDisable: false,
      done: false,
      group: "stayc",
      member: new Array(7).fill(0),
      time: Date.now(),
      rows: [],
      first: true,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    if (this.state.first) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState({
        first: false,
      });
    }
    fetch(domain + "/get_exports?session_id=" + Cookies.get("session_id"), {
      method: "POST",
      body: JSON.stringify({
        group: this.state.group,
        member: this.state.member,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          // not logged in
          if (response.status === 401) {
            window.location.href = window.location.origin + "/login";
          }
          throw "";
        } else {
          return response.json();
        }
      })
      .then((result) => {
        this.setState({
          exports: result.exports,
        });
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
          is_disable: false,
        });
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (
      this.state.height > this.state.width !==
      window.innerHeight > window.innerWidth
    ) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  groupChange = (event) => {
    this.setState({
      group: event.target.value,
      member: new Array(groups[event.target.value].length).fill(0),
    });
  };

  memberChange = (i) => {
    let member = [...this.state.member];
    if (this.state.group === "stayc") {
      member[i] = 1 - member[i];
    } else {
      member = member.fill(0);
      member[i] = 1;
    }
    this.setState({
      member: member,
      isDisable: false,
    });
  };

  click = () => {
    const { group, member } = this.state;
    this.setState({
      isDisable: true,
    });
    fetch(domain + "/export", {
      method: "POST",
      body: JSON.stringify({
        group: group,
        member: member,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        } else {
          return response.json();
        }
      })
      .then((result) => {})
      .catch((result) => {
        this.setState({
          isDisable: false,
        });
      });
  };

  render() {
    const { group } = this.state;

    const member_options = groups[group].map((member, i) => {
      return (
        <Form.Check
          inline
          checked={this.state.member[i]}
          onChange={() => this.memberChange(i)}
          label={member}
          name={i}
          type={"checkbox"}
          id={group + member}
        />
      );
    });

    return (
      <>
        <SimpleNavbar />
        <main ref="main">
          <div className="position-relative">
            <section
              className="section"
              style={{
                minHeight: "100vh",
              }}
            >
              <Container
                style={{
                  maxWidth: this.state.width > 1000 ? "70vw" : "100vw",
                }}
                className="mb-3"
              >
                <h2
                  style={{
                    color: "white",
                  }}
                >
                  Image Export
                </h2>
                <Form className="mt-2">
                  <Form.Row>
                    <Form.Group as={Col} lg={4} md={5} sm={6} xs={6}>
                      <Form.Label>Choose a Group</Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.group}
                        onChange={this.groupChange}
                        name={"group"}
                      >
                        {group_options}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>Choose Members</Form.Label>
                      <div>{member_options}</div>
                    </Form.Group>
                  </Form.Row>
                </Form>
                <div>
                  {this.state.member.some((e) => e === 1) ? (
                    <Button
                      className="btn-icon img-fluid"
                      color="primary"
                      onClick={this.click}
                      disabled={this.state.isDisable}
                    >
                      {this.state.isDisable ? (
                        <div>
                          Please Wait
                          <CircularProgress height="20px" color="#FFFFFF" />
                        </div>
                      ) : (
                        "Export"
                      )}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
                <br />
              </Container>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ImageExport;
