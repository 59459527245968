import React from "react";

import Cookies from "js-cookie";
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import "assets/css/slick_temp.css";
import SideBar from "components/Navbars/SideBar.js";
import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

const langs = [
  "English",
  "Malay",
  "Tagalog",
  "Italian",
  "Greek",
  "中文（繁體）- coming soon",
  "Spanish - coming soon",
];

function Gif(props) {
  return (
    <img
      alt={props.alt}
      src={props.src}
      style={{ position: "absolute" }}
      frameBorder="0"
      allowFullScreen
    />
  );
}

function Image(props) {
  return <img alt={props.alt} src={props.src} />;
}

function determineLang() {
  const lang = Cookies.get("lang");
  if (lang != null || lang in langs) {
    return lang;
  }
  switch (
    window.navigator.languages && window.navigator.languages.length
      ? window.navigator.languages[0]
      : window.navigator.language
  ) {
    case "en-US":
      return "English";
    case "ms":
      return "Malay";
    case "it":
      return "Italian";
    case "tl":
      return "Tagalog";
    case "tl-PH":
      return "Tagalog";
    case "el":
      return "Greek";
    default:
      return "English";
  }
}

class HomeClass extends React.Component {
  constructor(props) {
    super(props);
    const lang = determineLang();
    const interactive = Cookies.get("interactive") === "1" ? 1 : 0;
    this.state = {
      hover: [0, 0, 0, 0, 0, 0],
      disc: [0, 0],
      menuOpen: false,
      width: 0,
      height: 0,
      initialSet: false,
      lang: lang,
      interactive: interactive,
    };
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    Cookies.set("lang", lang, { expires: 365 });
    Cookies.set("interactive", interactive, { expires: 365 });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (
      this.state.height > this.state.width !==
      window.innerHeight > window.innerWidth
    ) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleHover(j, i) {
    const newHover = new Array(6).fill(0);
    newHover[i] = 1;
    if (j) {
      this.setState({
        disc: newHover,
      });
    } else {
      this.setState({
        hover: newHover,
      });
    }
  }

  handleLeave(j, i) {
    const newHover = this.state.hover.slice(0);
    newHover[i] = 0;
    if (j) {
      this.setState({
        disc: newHover,
      });
    } else {
      this.setState({
        hover: newHover,
      });
    }
  }

  text_langs = () => {
    switch (Cookies.get("lang", "English")) {
      case "Malay":
        return {
          welcome: "Selamat datang ke laman web peminat STAYC pertama",
          adapted:
            "Diadaptasi dari laman web rasmi dan diedit oleh @tasty. Diterjemahkan oleh @josh 2.0",
          first: (
            <span>
              Sejak memulakan perjalanan mereka dalam industri muzik, STAYC
              telah dipupuk dengan penekanan pada mantra "Sihat" bagi memupuk
              keperibadian yang disayangi dan menawan.
            </span>
          ),
          second: (
            <span>
              Sejak memulakan perjalanan mereka dalam industri muzik, STAYC
              telah dipupuk dengan penekanan pada mantra "Sihat" bagi memupuk
              keperibadian yang disayangi dan menawan.
            </span>
          ),
          third: (
            <span>
              STAYC berusaha untuk memperkasakan remaja dengan menerajui genre
              "Teen-Fresh" yang unik, dan dengan itu, era baru K-pop yang
              merangkumi bakat menakjubkan keenam-enam anggota, getaran
              inovatif, dan semangat perintis.
            </span>
          ),
        };
      case "Italian":
        return {
          welcome: "Benvenuti al primo e solo sito dei fan delle STAYC!",
          adapted:
            "Adattato dal sito ufficiale ed editato da @tasty. Tradotto da shia",
          first: (
            <span>
              STAYC è un gruppo femminile formato da sei membri da Highup che
              comprendono Sumin, Sieun, Isa, Seeun, Yoon, e J, che debuttarono
              nel Novembre 2020 con il brano "SO BAD" dal loro album singolo
              Star To A Young Culture.
            </span>
          ),
          second: (
            <span>
              Da quando iniziarono i loro viaggi nella musica, i membri delle
              STAYC nutrirono l'enfasi sul mantra "Healthy" per promuovere le
              loro personalità attraenti e accattivanti.{" "}
            </span>
          ),
          third: (
            <span>
              Le STAYC si impegnano a guidare gli adolescenti in un unico genere
              "Teen-Fresh", e con quello, una nuova era del K-pop che esprime lo
              straordinario talento, l'atmosfera innovativa e lo spirito
              intraprendente di tutti e sei membri.
            </span>
          ),
        };
      case "Tagalog":
        return {
          welcome:
            "Maligayang pagdating sa una at ang nag-iisang STAYC fan website!",
          adapted:
            "Galing sa opisyal na website by @tasty. Isinalin ni @yeochin / @shiiitake#1989",
          first: (
            <span>
              Ang STAYC ay isang grupo ng anim na kababaihan na binuo ng Highup
              Entertainment. Ang mga miyembro ng grupo ay sina Sumin, Sieun,
              Isa, Seeun, Yoon at J. Sila ay nag-debut noong Nobyembre 2020 sa
              kantang ipinamagatan na 'SO BAD' galing sa kanilang single album
              na Star To A Young Culture.
            </span>
          ),
          second: (
            <span>
              Simula pa noon hanggang ngayon nung sila ay lumakbay sa daan
              patungong k-pop, ang mga miyembro ng STAYC ay dumiin sa isang
              mantra na tawag ay “Healthy” para mapaikita ang mapagmahal at ang
              nakakaakit na personalidad ng mga miyembro.
            </span>
          ),
          third: (
            <span>
              Nagperperform ang STAYC na buhayin ang loob ng mga kabataan sa
              pamamagitan ng isang natatanging genre na "Teen-Fresh". At kasama
              nito, isang bagong panahon ng K-pop na sumasalamin sa
              nakamamanghang talento, makabagong vibe, at ang sigla ng
              pangunguna ng lahat ng anim na miyembro.
            </span>
          ),
        };
      default:
        return {
          welcome: "Welcome to the first and only STAYC fan website!",
          adapted: "Adapted from the official website and edited by @tasty:",
          first: (
            <span>
              STAYC is a six-member girl group from Highup consisting of Sumin,
              Sieun, Isa, Seeun, Yoon, and J, who debuted in November 2020 with
              "SO BAD" from their single album <i>Star To A Young Culture</i>.
            </span>
          ),
          second: (
            <span>
              Ever since embarking on their journeys in music, STAYC members
              have been nurtured with an emphasis on the mantra "Healthy" to
              foster their lovable and captivating personalities.
            </span>
          ),
          third: (
            <span>
              STAYC strives to empower teenagers by spearheading a unique
              "Teen-Fresh" genre, and with it, a new era of K-pop that embodies
              all six members&apos; stunning talent, innovative vibe, and
              pioneering spirit.
            </span>
          ),
        };
      case "Greek":
        return {
          welcome: "Καλώς ήρθατε στην πρώτη και μοναδική fanpage των STAYC!",
          adapted:
            "Εμπνευσμένη από την επίσημη ιστοσελίδα και επεξεργασμένη από τoν @tasty. Μετάφραση: Genos",
          first: (
            <span>
              Η STAYC είναι μια εξαμελής ομάδα κοριτσιών από το Highup που
              αποτελείται από τις Sumin, Sieun, Isa, Seeun, Yoon και J, οι
              οποίες έκαναν το ντεμπούτο τους τον Νοέμβριο του 2020 με το "SO
              BAD" από το single album Star To A Young Culture.
            </span>
          ),
          second: (
            <span>
              Από τότε που ξεκίνησαν την πορεία τους στη μουσική, τα μέλη των
              STAYC έχουν ανατραφεί με έμφαση στο μάντρα "Yγιές" ώστε να
              καλλιεργήσουν τις αξιαγάπητες και μαγευτικές προσωπικότητές τους.
            </span>
          ),
          third: (
            <span>
              Οι STAYC προσπαθούν να εμψυχώσουν τους εφήβους ξεκινώντας ένα
              μοναδικό "Teen-Fresh" είδος, και με αυτό, μια νέα εποχή στην K-pop
              που ενσωματώνει το εκπληκτικό ταλέντο και των έξι μελών, καινοτόμο
              vibe, και πρωτοποριακό πνεύμα.
            </span>
          ),
        };
    }
  };

  langChange = (lang) => {
    this.setState({
      lang: lang,
    });
  };

  interactiveChange = (interactive) => {
    this.setState({
      interactive: interactive,
    });
  };

  render() {
    const text_stuff = this.text_langs();

    const members = {
      SUMIN: {
        name: "수민",
        dob: "2001 03 13",
        img:
          //"https://cdn.discordapp.com/attachments/845656665150980096/908699215813836860/SUMIN_Profile.jpg",
          //"https://cdn.discordapp.com/attachments/872882586425376768/965295803776266260/2021_0414_1_edit.jpg"
          "https://cdn.discordapp.com/attachments/872882586425376768/965301303557361744/2021_0414_1_edit_light.jpg",
      },
      SIEUN: {
        name: "시은",
        dob: "2001 08 01",
        img:
          //"https://cdn.discordapp.com/attachments/845656665150980096/908699238286905366/SIEUN_Profile.jpg",
          //"https://cdn.discordapp.com/attachments/872882586425376768/965300218839052298/2020_1231_1_edit.jpg"
          "https://cdn.discordapp.com/attachments/872882586425376768/965300752128049292/2020_1231_1_edit_color.jpg",
      },
      ISA: {
        name: "아이사",
        dob: "2002 01 23",
        img:
          //"https://cdn.discordapp.com/attachments/845656665150980096/908699229273329694/ISA_Profile.jpg",
          "https://cdn.discordapp.com/attachments/872882586425376768/965311197429768192/2021_0204_1_edit.jpg",
      },
      SEEUN: {
        name: "세은",
        dob: "2003 06 14",
        img:
          //"https://cdn.discordapp.com/attachments/845656665150980096/908699234809819146/SEEUN_Profile.jpg",
          "https://cdn.discordapp.com/attachments/872882586425376768/965311186797203497/2021_0205_4_edit.jpg",
      },
      YOON: {
        name: "윤",
        dob: "2004 04 14",
        img:
          //"https://cdn.discordapp.com/attachments/845656665150980096/908699219848736768/YOON_Profile.jpg",
          //"https://cdn.discordapp.com/attachments/872882586425376768/964775791294574592/2022_0221_3_edit_red.jpg"
          "https://cdn.discordapp.com/attachments/872882586425376768/965312325018091611/2022_0221_3_edit_gradient.jpg",
      },
      J: {
        name: "재이",
        dob: "2004 12 09",
        img:
          //"https://cdn.discordapp.com/attachments/845656665150980096/908699230686830602/J_Profile.jpg",
          //"https://cdn.discordapp.com/attachments/872882586425376768/964778952700870676/2021_0415_1_edit.jpg",
          "https://cdn.discordapp.com/attachments/872882586425376768/965356876596408380/2021_0415_1_edit_gradient.jpg"
      },
    };
    const items = Object.keys(members).map((name, i) => {
      return (
        <div className="grid-item" key={name}>
          <figure
            className="profile-image none"
            data-duration={2}
            data-delay="0.3"
            onMouseEnter={(e) => this.handleHover(0, i)}
            onMouseLeave={(e) => this.handleLeave(0, i)}
          >
            {this.state.hover[i] && this.state.interactive ? (
              <div
                style={{
                  paddingBottom: "178%",
                  position: "relative",
                }}
                id={name}
              >
                <Gif alt={name + " gif"} src={"/img/" + name} />
              </div>
            ) : (
              <Image src={members[name].img} alt={name + " image"} />
            )}{" "}
          </figure>
          <h4
            className="none"
            style={{ color: "white", fontWeight: "bold" }}
            data-duration={1}
            data-delay="0.6"
          >
            {name}
          </h4>{" "}
          <p className="none" data-duration={1} data-delay="0.7">
            {members[name].name}
            <br />
            {members[name].dob}
          </p>{" "}
        </div>
      );
    });

    const disco = {
      "YOUNG-LUV.COM": {
        date: "2022 02 21",
        type: "Mini",
        img:
          "https://cdn.discordapp.com/attachments/872882586425376768/949220470237052928/STAYC_YOUNG-LUV.COM_Artwork.jpg",
        url: "",
        gif: "",
      },
      Stereotype: {
        date: "2021 09 06",
        type: "Mini",
        img:
          "https://cdn.discordapp.com/attachments/872882586425376768/941757033680629771/STAYC_STEREOTYPE_Artwork.jpg",
        url: "stereotype",
        gif: "",
      },
      Staydom: {
        date: "2021 04 08",
        type: "SINGLE",
        img:
          "https://cdn.discordapp.com/attachments/845656665150980096/908700815550730340/-1800x1800-1.jpg",
        url: "staydom",
        gif: "asap",
      },
      "Star To A Young Culture": {
        date: "2020 11 12",
        type: "SINGLE",
        img:
          "https://cdn.discordapp.com/attachments/845656665150980096/908700814015602708/so-bad-album-artwork.jpg",
        url: "star-to-a-young-culture",
        gif: "so_bad",
      },
    };
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow:
        this.props.isMobile && this.state.height > this.state.width ? 1 : 2,
      slidesToScroll: 1,
      arrows: false,
      variableWidth: true,
    };

    const discography = Object.keys(disco).map((name, i) => {
      return (
        <div
          id={name}
          key={name}
          className="album-item slick-slide slick-current slick-center"
          data-slick-index={0}
          aria-hidden="true"
          style={{
            maxHeight: "90vh",
            width:
              this.props.isMobile && this.state.height > this.state.width
                ? "90vw"
                : "30vw",
            position: "relative",
          }}
          tabIndex={0}
          {...(this.props.isMobile
            ? {}
            : {
                onMouseEnter: (e) => this.handleHover(1, i),
                onMouseLeave: (e) => this.handleLeave(1, i),
              })}
        >
          <a
            href={"http://www.highup-ent.com/en/stayc/" + disco[name].url + "/"}
            tabIndex={-1}
          >
            {this.state.disc[i] && this.state.interactive ? (
              <div
                style={{
                  paddingBottom: "100%",
                  position: "relative",
                }}
              >
                <Gif alt={name + " gif"} src={"/img/" + disco[name].gif} />
              </div>
            ) : (
              <Image
                src={disco[name].img}
                alt={name + " image"}
                frameBorder="0"
              />
            )}
            <div className="album-info">
              <h4
                className="none"
                style={{ color: "white", fontWeight: "bold" }}
                data-duration={1}
                data-delay="0.7"
              >
                {name}
              </h4>
              <p className="none" data-duration={1} data-delay="0.8">
                {disco[name].type}
                <br />
                {disco[name].date}
              </p>
            </div>
          </a>{" "}
        </div>
      );
    });

    return (
      <>
        <SideBar
          langChange={this.langChange}
          interactiveChange={this.interactiveChange}
        />
        <SimpleNavbar />
        <div className="site-main">
          <div className="page-cover" style={{ height: "100vh" }}>
            <div className="outer-margin">
              <div className="section-content" style={{ bottom: 5 }}>
                <h1
                  className="headline"
                  style={{
                    color: "white",
                    fontWeight: "900",
                    fontFamily: 'Gilroy, "Noto Sans KR", sans-serif',
                  }}
                >
                  <div className="text-mask">
                    <span>STAYC GIRLS...</span>
                  </div>
                  <div className="text-mask">
                    <span>IT'S GOING DOWN</span>
                  </div>
                </h1>
                <h3
                  className="headline"
                  style={{
                    color: "white",
                    fontWeight: "900",
                    fontSize: this.props.isMobile ? "4vw" : "2em",
                    fontFamily: 'Gilroy, "Noto Sans KR", sans-serif',
                    //wordSpacing: "1rem",
                  }}
                >
                  <div>
                    <span>SUMIN SIEUN ISA SEEUN YOON J</span>
                  </div>
                </h3>
              </div>
            </div>
            <div className="background">
              <div className="image landscape background-image-hor" />
              <div className="image portrait background-image-vert" />
            </div>
          </div>
          <div className="page-content">
            <div className="manifesto section active">
              <div className="outer-margin">
                <h3
                  className="section-heading none"
                  style={{ color: "white", paddingRight: "45px" }}
                >
                  {text_stuff.welcome}
                </h3>
                <div className="section-content" style={{ fontWeight: "bold" }}>
                  {text_stuff.adapted}
                  <br />
                  <br />
                  <p
                    className="none"
                    data-duration={1}
                    data-delay="0.3"
                    style={{ fontWeight: "bold" }}
                  >
                    {text_stuff.first}
                  </p>
                  <p
                    className="none"
                    data-duration={1}
                    data-delay="0.4"
                    style={{ fontWeight: "bold" }}
                  >
                    {text_stuff.second}
                  </p>
                  <p
                    className="none"
                    data-duration={1}
                    data-delay="0.5"
                    style={{ fontWeight: "bold" }}
                  >
                    {text_stuff.third}
                  </p>{" "}
                  <div className="social-links none" data-delay="0.7">
                    <span>STAYC Official</span>
                    <a
                      className="text-button"
                      href="http://cafe.daum.net/STAYC"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Fancafe
                    </a>
                    <a
                      className="text-button"
                      href="http://youtube.com/STAYC"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Youtube
                    </a>
                    <a
                      className="text-button"
                      href="http://instagram.com/stayc_highup"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Instagram
                    </a>
                    <a
                      className="text-button"
                      href="http://twitter.com/STAYC_official"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Twitter
                    </a>
                    <a
                      className="text-button"
                      href="http://bit.ly/3nqGTzf"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Facebook
                    </a>
                    <a
                      className="text-button"
                      href="http://tiktok.com/@stayc_official"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      TikTok
                    </a>
                    <a
                      className="text-button"
                      href="https://www.vlive.tv/channel/968D15"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      V LIVE
                    </a>
                  </div>
                  <div className="social-links none" data-delay="0.7">
                    <span>Resources</span>
                    <a className="text-button" href="/img">
                      Reverse Image Search
                    </a>
                    {/*<a className="text-button" href="/browse">
                      Image Browser
                    </a>*/}
                    <a className="text-button" href="/archive">
                      Image Archive
                    </a>
                    <a className="text-button" href="/wallpapers">
                      Wallpapers
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile section active">
              <div className="outer-margin">
                <h3
                  className="section-heading none"
                  style={{ fontWeight: "bold", color: "white" }}
                >
                  Members
                </h3>
                <div className="section-content">
                  <div className="profile-grid">{items}</div>
                </div>
              </div>
            </div>
            <div className="discography section active">
              <div className="outer-margin">
                <h3
                  className="section-heading none"
                  style={{ fontWeight: "bold", color: "white" }}
                >
                  Discography
                </h3>
                <Slider
                  className={`album-list has-two-items ${this.props.styleName}`}
                  style={{ paddingLeft: "30px" }}
                  {...settings}
                >
                  {discography}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer">
          <Container>
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className="brand-copy">STAR TO A YOUNG CULTURE</div>
                <div className="copyright">
                  STAYC® is a registered trademark of High Up Entertainment,
                  which is not affiliated with and does not endorse this
                  website.
                </div>
              </Col>
              <Col md="6">
                <Nav className=" nav-footer justify-content-end">
                  <NavItem>
                    <NavLink href="/about">About</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="mailto:swithsstayc (at) gmail.com">
                      Contact
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/privacy">Privacy Policy</NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="loading-curtain" />
        <div className="nav-curtain" />
      </>
    );
  }
}

const Home = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return <HomeClass isMobile={isTabletOrMobile} />;
};

export default Home;
