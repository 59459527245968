import React from "react";

import "../../assets/css/global.css";
import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

class J extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "black",
      visible: true,
      muted: true,
      clicked: false,
    };
  }

  listenScrollEvent = (e) => {
    if (window.scrollY > window.innerHeight * 1.1) {
      this.setState({
        visible: false,
      });
      this.refs.vidRef.play();
    } else if (window.scrollY > window.innerHeight / 3.5) {
      if (!this.state.visible) {
        this.refs.vidRef.pause();
      }
      this.setState({ color: "white", visible: true });
    } else {
      this.setState({ color: "black", visible: true });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  click = () => {
    if (this.state.muted) {
      this.refs.vidRef.muted = false;
      this.setState({
        muted: false,
        clicked: true,
      });
    } else {
      this.refs.vidRef.muted = true;
      this.setState({
        muted: true,
        clicked: true,
      });
    }
  };

  render() {
    const { color } = this.state;
    return (
      <>
        <SimpleNavbar />
        <main ref="main">
          <div className="jbackground"></div>
          <div className="jbackground2"></div>
          <div
            style={{
              width: "100vw",
              position: "relative",
            }}
            onClick={this.click}
          >
            <video
              muted
              loop
              autoPlay
              playsInline
              ref="vidRef"
              src="https://cdn.discordapp.com/attachments/685309795380494355/971549316864946256/jselca_v2.mp4"
              className="centervideo"
              style={{ height: "100vh", maxWidth: "100vw", top: "0" }}
            />
            {this.state.muted ? (
              <div
                style={{
                  fontWeight: "bold",
                  position: "absolute",
                  top: "50vh",
                  right: "2vw",
                }}
                className="blink_me"
              >
                Click for sound
              </div>
            ) : (
              ""
            )}
          </div>
          {this.state.visible ? (
            <div
              className="container"
              style={{
                position: "fixed",
                left: "10vw",
                top: "70vh",
              }}
            >
              <h1
                style={{
                  color: color,
                }}
              >
                Jang Yeeun
              </h1>
              <h3
                className="mb-3"
                style={{
                  color: color,
                  textTransform: "none",
                }}
              >
                [noun]
              </h3>
              <h2
                className="mb-3"
                style={{
                  color: color,
                  textTransform: "none",
                }}
              >
                1. The best girl in the whole entire world.
              </h2>
            </div>
          ) : (
            ""
          )}
        </main>
      </>
    );
  }
}

export default J;
