/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer blackfooter">
          <Container>
            <Row className=" align-items-center justify-content-md-between">
              <Col md="4"></Col>
              <Col md="8">
                <Nav className=" nav-footer justify-content-end">
                  <NavItem>
                    <NavLink href="/">Home</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/img">Reverse Image Search</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/browse">Image Browser</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/archive">Image Archive</NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
