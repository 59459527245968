export const isa_bunny_selcas = [
  "https://cdn.discordapp.com/attachments/890712623279140914/974970440407724032/2020_1023_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970442760749066/2020_1124_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970444883042344/2020_1229_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970447621914694/2021_0107_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970449450655824/2021_0408_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970472276049940/2021_0414_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970475723780136/2021_0511_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970486985474068/2021_0603_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970495210487818/2021_0611_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970503183876166/2021_0611_2.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970510368714772/2021_0722_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970513560596530/2021_0919_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970527129165854/2021_1102_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970538420207616/2021_1110_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970542400618496/2021_1122_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970545189842994/2021_1123_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970546972405760/2021_1127_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970574407352370/2021_1222_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970576345104394/2022_0226_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970578157047838/2022_0228_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970580342280192/2022_0228_3.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970582309417060/2022_0305_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970596628770848/2022_0309_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970605172580362/2022_0405_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970613796053002/2022_0405_2.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970615662518352/2022_0410_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970623367450654/2022_0412_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970631055609926/2022_0412_2.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970632985006120/2022_0510_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970635312824340/2022_0514_1.jpg",
  "https://cdn.discordapp.com/attachments/890712623279140914/974970639507161178/2022_0514_2.jpg",
];
