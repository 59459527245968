import React from "react";

import "../../assets/css/global.css";
// core components
import SimpleFooter from "components/Footers/SimpleFooter.js";

import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

import { LazyLoadComponent } from "react-lazy-load-image-component";

const links = [
  "https://cdn.discordapp.com/attachments/802320243108085801/960425952050049104/2022_0221_4_ccccff.jpg",
  "https://cdn.discordapp.com/attachments/685309795380494355/955545314373300375/2022_0121_2.jpg",
  "https://cdn.discordapp.com/attachments/942531089396793494/952599472087633942/2021_0625_1_edit.jpg",
  "https://cdn.discordapp.com/attachments/942531089396793494/952599309046644776/2020_1014_1_edit.jpg",
  "https://cdn.discordapp.com/attachments/942531089396793494/952599114774896680/2022_0303_5_edited_space_excellect.jpg",
  "https://cdn.discordapp.com/attachments/942531089396793494/952599188376539196/2020_1028_2_edit_darker_background.jpg",
  "https://cdn.discordapp.com/attachments/942531089396793494/952599416219525240/2022_0309_1_edit_centered_v4.jpg",
  "https://cdn.discordapp.com/attachments/942531089396793494/952648106195943424/2021_0114_1_edit.jpg",
];

class Wallpapers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const wallpapers = links.map((w) => {
      return (
        <LazyLoadComponent>
          <img
            style={{
              maxHeight: "60vh",
              maxWidth: "25vw",
            }}
            src={w}
            key={w}
            alt={w}
          ></img>
        </LazyLoadComponent>
      );
    });

    return (
      <>
        <SimpleNavbar />
        <main ref="main">
          <div className="position-relative">
            <section
              className="section"
              style={{
                minHeight: "100vh",
              }}
            >
              <div className="container">
                <h2 className="mb-3" style={{ color: "white" }}>
                  Wallpapers
                </h2>
                <div>{wallpapers}</div>
              </div>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Wallpapers;
