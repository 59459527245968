import React from "react";
import { Redirect } from "react-router-dom";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

import "assets/css/global.css";
// core components
import SimpleFooter from "components/Footers/SimpleFooter.js";

import { Form } from "react-bootstrap";

import CircularProgress from "react-cssfx-loading/lib/CircularProgress";

import Cookies from "js-cookie";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Dropzone from "react-dropzone";

import DataTable from "react-data-table-component";

//import { Masonry } from "@mui/lab";
import { Masonry } from "masonic";
import { Switch, FormGroup, FormControlLabel } from "@mui/material";

import { LazyLoadComponent } from "react-lazy-load-image-component";

import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

import streamSaver from "streamsaver";

const domain =
  process.env.REACT_APP_REL === "TRUE" ? "" : "https://staycswith.com";

const groups_master = {
  stayc: ["group", "sumin", "sieun", "isa", "seeun", "yoon", "j"],
  ive: ["group", "gaeul", "yujin", "rei", "wonyoung", "liz", "leeseo"],
  newjeans: ["group", "minji", "hanni", "danielle", "haerin", "hyein"],
  nmixx: [
    "group",
    "lily",
    "haewon",
    "sullyoon",
    "jinni",
    "bae",
    "jiwoo",
    "kyujin",
  ],
  viviz: ["group", "eunha", "sinb", "umji"],
};

const paginationComponentOptions = {
  selectAllRowsItem: true,
};

const customStyles = {
  rows: {
    style: {
      fontSize: "1rem",
    },
  },
  head: {
    style: {
      fontSize: "1.3rem",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "transparent",
    },
  },
};

const twitter_regex = new RegExp(
  "^https://(www.)?twitter.com/[a-zA-Z0-9_]+/status/([0-9]+)"
);
const instagram_regex = new RegExp(
  "^https://(?:www.)?instagram.com/(.*?)p/([a-zA-Z0-9_-]+)"
);
const naver_regex = new RegExp("^https://.*?naver.(com|me)/([a-zA-Z0-9]+)");
const sbs_regex = new RegExp("^https://programs.sbs.co.kr/([a-zA-Z0-9]+)");

const weverse_regex = new RegExp(
  "^https://(www.)?weverse.io/[a-zA-Z]+/(artist|moment)/[0-9a-z-/]+"
);

const melon_regex = new RegExp(
  "^https://(m2|www).melon.com/musicstory/.*?mstorySeq=[0-9]+"
);

// dipe.co.kr is repost of dispatch and lower res
// 2022 0922 update: found exclusive article on dipo. not available on dispatch
const dispatch_regex = new RegExp("^https://www.(dispatch|dipe).co.kr/[0-9]+");
//const dispatch_regex = new RegExp("^https://www.dispatch.co.kr/[0-9]+");

const osen_regex = new RegExp("^https?://osen.mt.co.kr/article/[a-zA-Z0-9]+");

const MasonryItem = ({ data: { url, display_media_url, to_display } }) => {
  if (to_display) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {display_media_url}
      </a>
    );
  } else {
    return "";
  }
};

class Archive extends React.Component {
  constructor(props) {
    super(props);

    Cookies.set("return", "/archive", { expires: 1 });

    // group
    let group = props.match.params.group || Cookies.get("group") || "STAYC";
    if (group.toLowerCase() in groups_master) {
      group = group.toUpperCase();
    } else {
      group = "STAYC";
    }
    Cookies.set("group", group, { expires: 10 });

    // member
    let member = props.match.params.member || Cookies.get("member") || "group";
    if (groups_master[group.toLowerCase()].includes(member)) {
      member = member.toLowerCase();
    } else {
      if (group == "IVE") {
        member = "yujin";
      } else if (group == "NMIXX") {
        member = "lily";
      } else if (group == "STAYC") {
        member = "j";
      } else {
        member = "group";
      }
    }

    Cookies.set("member", member, { expires: 10 });

    let no_sort = Cookies.get("no_sort") || "false";
    no_sort = no_sort === "true";
    const authorized = Cookies.get("authorized") || "false";
    this.state = {
      link: "",
      media_urls: "",
      archive: [],
      filtered_archive: [],
      added: 0,
      deleted: 0,
      preview_images: [],
      preview_videos: [],
      images_selected: [],
      videos_selected: [],
      media: [],
      is_disable: false,
      group: group,
      member: member,
      clicked: false,
      resetPaginationToggle: false,
      filterText: "",
      loading: true,
      date: new Date(),
      selected: [],
      media_type: Cookies.get("media_type") || "twitter",
      show_images: Cookies.get("show_images") || "true",
      authorized: authorized,
      authorized_view: authorized === "true",
      no_sort: no_sort,
      masonry: false,
      unsorted: true,
      downloading: false,
      send_discord: Cookies.get("send_discord") || "true",
      fansite: "false",
      description: "",
      width: 0,
      height: 0,
      batch: window.location.pathname.split("/")[1] === "batch",
      generic_preview: false,
    };
    this.click_preview = this.click_preview.bind(this);
  }

  componentDidMount() {
    this.retrieve_archive();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (
      this.state.height > this.state.width !==
      window.innerHeight > window.innerWidth
    ) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  retrieve_archive = () => {
    if (
      this.state.group.toLowerCase() in groups_master &&
      groups_master[this.state.group.toLowerCase()].includes(
        this.state.member.toLowerCase()
      ) &&
      this.state.member.toLowerCase() !== "choose a member"
    ) {
      this.setState({
        loading: true,
      });
      fetch(domain + "/get_archive?session_id=" + Cookies.get("session_id"), {
        method: "POST",
        body: JSON.stringify({
          group: this.state.group,
          member: this.state.member,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            // not logged in
            if (response.status === 401) {
              window.history.pushState({}, "", domain + "/login");
            }
            throw response.json();
          } else {
            return response.json();
          }
        })
        .then((result) => {
          this.setState({
            archive: result.archived_data,
            filtered_archive: JSON.parse(
              JSON.stringify(
                result.archived_data.filter((row) => {
                  for (const [, v] of Object.entries(row)) {
                    if (v.toLowerCase().includes(this.state.filterText)) {
                      return true;
                    }
                  }
                  return false;
                })
              )
            ),
          });
        })
        .catch((error) => {
          this.setState({
            error: true,
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
            is_disable: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  click = () => {
    this.setState({
      is_disable: true,
    });

    const nottwitter_instagram_naver = this.auto_mt();

    // CHECK THAT THERE IS ALL REQUIRED FIELDS:
    // NEED: GROUP, MEMBER, SOURCE, LINK, SELECTED MEDIA, DATE (FOR ALL EXCEPT INSTAGRAM)
    // IMPORTANT!!!!!!!!!!!!!!!!!!

    let selected_count = false;

    let body = "";
    if (nottwitter_instagram_naver) {
      let images = "";
      if (this.state.generic_preview) {
        this.state.images_selected.forEach((val, i) => {
          if (val) {
            images += this.state.preview_images[i] + "\n";
          }
        });
      } else {
        images = this.state.media_urls;
      }

      selected_count = this.state.media.length !== 0 || images !== "";

      var formdata = new FormData();
      for (var i = 0; i < this.state.media.length; i++) {
        const file_type = this.state.media[i].type;
        if (file_type.includes("image")) {
          formdata.append("images", this.state.media[i]);
        } else if (file_type.includes("video")) {
          formdata.append("videos", this.state.media[i]);
        }
      }
      formdata.append("link", this.state.link);
      formdata.append("media_urls", images);
      formdata.append("media_type", this.state.media_type);
      formdata.append("group", this.state.group);
      formdata.append("member", this.state.member);
      formdata.append("description", this.state.description);
      formdata.append(
        "send_discord",
        this.state.send_discord === "true" ? 1 : ""
      );
      formdata.append("fansite", this.state.fansite === "true" ? 1 : "");

      let date = this.state.date;

      const mm = date.getMonth() + 1; // zero indexed
      const dd = date.getDate();
      date = [
        date.getFullYear(),
        (mm > 9 ? "" : "0") + mm,
        (dd > 9 ? "" : "0") + dd,
      ].join("");

      formdata.append("date", date);
      body = formdata;
    } else {
      // is twitter, instagram, naver, or sbs. handled automatically
      selected_count = this.state.images_selected
        .concat(this.state.videos_selected)
        .filter((v) => {
          return v === 1;
        }).length;
      if (selected_count) {
        let images = "";
        this.state.images_selected.forEach((val, i) => {
          if (val) {
            images += this.state.preview_images[i] + "\n";
          }
        });
        let videos = "";
        this.state.videos_selected.forEach((val, i) => {
          if (val) {
            videos += this.state.preview_videos[i] + "\n";
          }
        });
        body = JSON.stringify({
          link: this.state.link,
          images: images,
          videos: videos,
          media_type: this.state.media_type,
          date: this.state.date,
          group: this.state.group,
          member: this.state.member,
          description: this.state.description,
          send_discord: this.state.send_discord === "true" ? 1 : "",
          fansite: this.state.fansite === "true" ? 1 : "",
          username: this.state.username,
        });
      }
    }
    if (selected_count) {
      fetch(
        domain + "/update_archive?session_id=" + Cookies.get("session_id"),
        {
          method: "POST",
          body: body,
        }
      )
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401) {
              window.history.pushState({}, "", domain + "/login");
            }
            throw response.json();
          } else {
            return response.json();
          }
        })
        .then((result) => {
          this.setState({
            added: result.added,
            errors: result.errors,
            duplicate: result.duplicate,
          });
          // if no errors, clear out
          if (!result.errors) {
            // get new archive
            this.retrieve_archive();
            this.setState({
              deleted: 0,
            });
            // if any images not selected or if media type that is not automatic
            if (
              this.state.preview_images.length +
                this.state.preview_videos.length -
                selected_count &&
              (!nottwitter_instagram_naver || this.state.generic_preview)
            ) {
              this.setState({
                media_urls: "",
                media: [],
                images_selected: new Array(
                  this.state.images_selected.length
                ).fill(0),
                videos_selected: new Array(
                  this.state.videos_selected.length
                ).fill(0),
                no_sort: true,
                is_disable: false,
              });
            }
            // all images selected
            else {
              this.setState({
                link: "",
                description: "",
                media_urls: "",
                media: [],
                preview_images: [],
                preview_videos: [],
                images_selected: [],
                videos_selected: [],
                no_sort: true,
                is_disable: false,
              });
            }
          } else {
            this.setState({
              is_disable: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            error: true,
            is_disable: false,
          });
        });
    } else {
      this.setState({
        is_disable: false,
      });
    }
  };

  auto_mt = () => {
    return ![
      "",
      "twitter",
      "instagram",
      "naver",
      "sbs",
      "weverse",
      "melon",
      "dispatch",
      "osen",
    ].includes(this.state.media_type);
  };

  async click_preview(_event, javascript = false) {
    this.setState({
      is_disable: true,
    });

    let validator = "";
    let link = this.state.link;
    if (link.match("^http://")) {
      link = link.replace("http://", "https://");
    }
    if (this.state.media_type === "twitter") {
      validator = twitter_regex;
    } else if (this.state.media_type === "instagram") {
      validator = instagram_regex;
      link = link.replace("/tv/", "/p/").replace("/reel/", "/p/");
      link = link.split("?")[0];
      if (!link.endsWith("/")) {
        link += "/";
      }
    } else if (this.state.media_type === "naver") {
      validator = naver_regex;
    } else if (this.state.media_type === "sbs") {
      validator = sbs_regex;
    } else if (this.state.media_type === "weverse") {
      validator = weverse_regex;
    } else if (this.state.media_type === "melon") {
      validator = melon_regex;
    } else if (this.state.media_type === "dispatch") {
      validator = dispatch_regex;
    } else if (this.state.media_type === "osen") {
      validator = osen_regex;
    }

    const validatematch = link.match(validator);

    if (validatematch || this.state.media_type === "other") {
      let path = "";
      if (this.state.media_type === "twitter") {
        path = "/preview_tweet";
      } else if (this.state.media_type === "instagram") {
        path = "/preview_instagram";
        link = "https://www.instagram.com/p/" + validatematch[2] + "/";
        this.setState({
          link: link,
        });
      } else if (this.state.media_type === "sbs") {
        path = "/preview_sbs";
      } else if (this.state.media_type === "weverse") {
        link = link.split("?")[0];
        this.setState({
          link: link,
        });
        path = "/preview_weverse";
      } else if (this.state.media_type === "melon") {
        // fetch melon http and scrape
        let data = "";
        data = await fetch("https://stayc.onrender.com/" + link)
          .then((response) => {
            if (!response.ok) {
              throw response.text();
            } else {
              return response.text();
            }
          })
          .catch((error) => {});
        const melon2 = /img width="100%" src="https:\/\/cdnimg.melon.co.kr\/resource\/image.*?\.jpg"/gi;
        const matches = [...data.matchAll(melon2)].map((m) => {
          return m[0].substring(22, m[0].length - 1);
        });

        const melon3 = /option selected="selected" value="[0-9]+">\s*([0-9]+\.[0-9]+\.[0-9]+)/i;
        let date = data.match(melon3);
        date = date[1].replaceAll(".", "");

        this.setState({
          preview_images: matches,
          images_selected: new Array(matches.length).fill(
            matches.length === 1 ? 1 : 0
          ),
          date: date,
        });
        this.setState({ is_disable: false });
        return;
      } else if (this.state.media_type === "dispatch") {
        let data = "";
        data = await fetch("https://stayc.onrender.com/" + link)
          .then((response) => {
            if (!response.ok) {
              throw response.text();
            } else {
              return response.text();
            }
          })
          .catch((error) => {});
        const dispatch2 = /img src="https:\/\/dispatch.cdnser.be\/cms-content\/uploads.*?\.(jpeg|jpg|png)"/gi;
        const matches = [...data.matchAll(dispatch2)].map((m) => {
          return m[0].substring(9, m[0].length - 1);
        });
        let date = "";
        if (link.includes("dispatch.co.kr")) {
          const dispatch3 = /p class="article-created-at">.*?([0-9]+\.[0-9]+\.[0-9]+)/i;
          date = data.match(dispatch3);
          date = date[1].replaceAll(".", "");
        } else if (link.includes("dipe.co.kr")) {
          const dispatch3 = /div class="post-date">[\n\r\s]+([0-9]+\/[0-9]+\/[0-9]+)/i;
          date = data.match(dispatch3);
          date = date[1].replaceAll("/", "");
          date = date.substring(4, date.length) + date.substring(0, 4);
        }
        this.setState({
          preview_images: matches,
          images_selected: new Array(matches.length).fill(
            matches.length === 1 ? 1 : 0
          ),
          date: date,
        });
        this.setState({ is_disable: false });
        return;
      } else if (this.state.media_type === "osen") {
        let data = "";
        data = await fetch("https://stayc.onrender.com/" + link)
          .then((response) => {
            if (!response.ok) {
              throw response.text();
            } else {
              return response.text();
            }
          })
          .catch((error) => {});
        const osen2 = /img alt="" class="view_photo up" src="https?:\/\/file.osen.co.kr\/article.*?\.jpg"/gi;
        const matches = [...data.matchAll(osen2)].map((m) => {
          return m[0].substring(38, m[0].length - 1).replace("http:", "https:");
        });
        const osen3 = /div class="detailTitle__post-infos">([0-9]+-[0-9]+-[0-9]+)/i;
        let date = data.match(osen3);
        date = date[1].replaceAll("-", "");

        this.setState({
          preview_images: matches,
          images_selected: new Array(matches.length).fill(
            matches.length === 1 ? 1 : 0
          ),
          date: date,
        });
        this.setState({ is_disable: false });
        return;
      } else if (this.state.media_type === "other") {
        let data = "";
        if (javascript) {
          data = await fetch(
            domain +
              "/preview_javascript" +
              "?session_id=" +
              Cookies.get("session_id"),
            {
              method: "POST",
              body: JSON.stringify({
                link: link,
              }),
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw response.text();
              } else {
                return response.text();
              }
            })
            .catch((error) => {});
        } else {
          data = await fetch("https://stayc.onrender.com/" + link)
            .then((response) => {
              if (!response.ok) {
                throw response.text();
              } else {
                return response.text();
              }
            })
            .catch((error) => {});
        }
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "text/html");

        const matches = [...doc.images]
          .filter((m) => {
            return (
              !(m.src.includes("staycswith") || m.src.includes("localhost")) &&
              m.src
            );
          })
          .map((m) => {
            return m.src;
          });
        console.log(matches);
        console.log(
          [...doc.images].filter((m) => {
            return (
              !(m.src.includes("staycswith") || m.src.includes("localhost")) &&
              m.src
            );
          })
        );

        this.setState({
          preview_images: matches,
          images_selected: new Array(matches.length).fill(
            matches.length === 1 ? 1 : 0
          ),
          generic_preview: true,
        });
        this.setState({ is_disable: false });
        return;
      } else if (this.state.media_type === "naver") {
        let data = "";
        // if desktop version, get mobile version
        if (link.includes("entertain.naver.com")) {
          link = link.replace("/now", "");
          await fetch("https://stayc.onrender.com/" + link)
            .then((response) => {
              if (!response.ok) {
                throw response.text();
              } else {
                return response.text();
              }
            })
            .then((result) => {
              const naver2 = /"https:\/\/n.news.naver.com\/entertain\/article.*?"/gi;
              const match = naver2.exec(result);
              if (match != null) {
                link = match[0].substring(1, match[0].length - 1);
                this.setState({
                  link: link,
                });
              }
            })
            .catch((error) => {});
        }
        // if shortened link, get full link
        if (link.includes("naver.me")) {
          data = await fetch("https://stayc.onrender.com/" + link)
            .then((response) => {
              if (!response.ok) {
                throw response.json();
              } else {
                return response.json();
              }
            })
            .then((resp) => {
              link = resp.link;
              this.setState({ link: link });
              return resp.data;
            })
            .catch((error) => {});
        }
        // if standard naver link
        else {
          data = await fetch("https://stayc.onrender.com/" + link)
            .then((response) => {
              if (!response.ok) {
                throw response.text();
              } else {
                return response.text();
              }
            })
            .catch((error) => {});
        }
        if (link.includes("post.naver.com")) {
          const naver = /<img.*?data-src=".*?post-phinf.pstatic.net.*?.(jpg|png|jpeg)\\?.*?".*?>/gi;
          //const naver2 = /<img.*?id=.*?>/gi;
          const naver3 = /data-src=".*?post-phinf.pstatic.net.*?.(jpg|png|jpeg)\\?.*?"/gi;
          const matches = [...data.matchAll(naver)]
            .filter((m) => {
              return !(
                m[0].match(naver) === null || m[0].includes("type=f120_120")
              );
            })
            .map((m) => {
              const temp = m[0].match(naver3)[0];
              return temp.substring(10, temp.length - 1);
            });

          const naver4 = /<meta property="og:createdate" content=".*?\..*?\..*?\./gi;
          let date = data.match(naver4);
          date = date[0].substring(40, date[0].length).replaceAll(".", "");
          this.setState({
            preview_images: matches,
            images_selected: new Array(matches.length).fill(
              matches.length === 1 ? 1 : 0
            ),
            date: date,
          });
        } else if (link.includes("news.naver.com")) {
          const naver2 = /<img id=.*?data-src=".*?imgnews.pstatic.net.*?.(jpg|png|jpeg)\\?.*?"/gi;
          const matches = [...data.matchAll(naver2)].map((m) => {
            const temp = m[0].split("data-src=")[1];
            return temp.substring(1, temp.length - 1);
          });
          const naver3 = /data-date-time=".*?">([0-9]+\.[0-9]+\.[0-9]+\.)/i;
          let date = data.match(naver3);
          date = date[1].replaceAll(".", "");
          this.setState({
            preview_images: matches,
            images_selected: new Array(matches.length).fill(
              matches.length === 1 ? 1 : 0
            ),
            date: date,
          });
        }
        this.setState({ is_disable: false });
        return;
      }

      if (path !== "") {
        fetch(domain + path + "?session_id=" + Cookies.get("session_id"), {
          method: "POST",
          body: JSON.stringify({
            link: link,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw response.json();
            } else {
              return response.json();
            }
          })
          .then((result) => {
            const fill =
              result.images.length + result.videos.length === 1 ? 1 : 0;
            this.setState({
              preview_images: result.images,
              preview_videos: result.videos,
              images_selected: new Array(result.images.length).fill(fill),
              videos_selected: new Array(result.videos.length).fill(fill),
              date: result.date,
              username: result.username,
            });
            if (this.state.media_type === "twitter") {
              this.setState({
                link: result.link,
              });
            }
          })
          .catch((error) => {
            this.setState({});
          })
          .finally(() => {
            this.setState({ is_disable: false });
          });
      } else {
        this.setState({
          preview_images: [],
          preview_videos: [],
          images_selected: [],
          videos_selected: [],
          is_disable: false,
        });
      }
    } else {
      this.setState({
        preview_images: [],
        preview_videos: [],
        images_selected: [],
        videos_selected: [],
        is_disable: false,
      });
    }
  }

  mediaChange = (event) => {
    const value = event.target.value;
    this.setState({
      media_urls: value,
    });
  };

  linkChange = (event) => {
    const value = event.target.value.trim();
    this.setState({
      link: value,
      added: 0,
      deleted: 0,
      duplicate: 0,
      errors: 0,
      media: [],
      preview_images: [],
      preview_videos: [],
      images_selected: [],
      videos_selected: [],
      generic_preview: false,
    });
    let iglink = value.replace("/tv/", "/p/").replace("/reel/", "/p/");
    iglink = iglink.split("?")[0];
    if (iglink.match(instagram_regex)) {
      this.setState({
        media_type: "instagram",
        media_urls: "",
        link: iglink,
      });
    } else if (value.match(twitter_regex)) {
      this.setState({
        media_type: "twitter",
        media_urls: "",
        link: twitter_regex.exec(value)[0],
      });
    } else if (value.match(naver_regex)) {
      this.setState({
        media_type: "naver",
        media_urls: "",
      });
    } else if (value.match(sbs_regex)) {
      this.setState({
        media_type: "sbs",
        media_urls: "",
      });
    } else if (value.match(weverse_regex)) {
      let weverselink = value;
      if (!value.includes("www.")) {
        weverselink = "https://www." + value.substring(8);
      }
      this.setState({
        media_type: "weverse",
        media_urls: "",
        link: weverselink,
      });
    } else if (value.match(melon_regex)) {
      let melonlink = value;
      melonlink = melonlink.replace("m2", "www");
      this.setState({
        media_type: "melon",
        media_urls: "",
        link: melonlink,
      });
    } else if (value.match(dispatch_regex)) {
      let dispatchlink = value;
      dispatchlink = dispatchlink.split("?")[0];
      this.setState({
        media_type: "dispatch",
        media_urls: "",
        link: dispatchlink,
      });
    } else if (value.match(osen_regex)) {
      let osenlink = value;
      osenlink = osenlink.split("?")[0].replace("http:", "https:");
      this.setState({
        media_type: "osen",
        media_urls: "",
        link: osenlink,
      });
    } else {
      // add facebook, fancafe, universe regex
      this.setState({
        media_type: "other",
        media_urls: "",
      });
    }

    // find value in archive
    const num_matches = this.state.archive.filter((row) => {
      for (const [, v] of Object.entries(row)) {
        if (v.toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
      }
      return false;
    }).length;
    this.setState({
      matches: num_matches,
    });
  };

  select(i) {
    const items = [...this.state.images_selected];
    items[i] = items[i] ? 0 : 1;
    this.setState({ images_selected: items });
  }

  v_select(i) {
    const items = [...this.state.videos_selected];
    items[i] = items[i] ? 0 : 1;
    this.setState({ videos_selected: items });
  }

  select_all = (event) => {
    const img_len = this.state.preview_images.length;
    const vid_len = this.state.preview_videos.length;
    const fill = this.state.images_selected
      .concat(this.state.videos_selected)
      .every((v) => v === 1)
      ? 0
      : 1;
    this.setState({
      images_selected: new Array(img_len).fill(fill),
      videos_selected: new Array(vid_len).fill(fill),
    });
  };

  deleteRows = () => {
    this.setState({
      added: 0,
    });
    const rows = this.state.selected.selectedRows.map((row) => {
      return {
        filename: row.filename,
        group: row.group,
        name: row.name,
        media_type: row.media_type,
      };
    });
    fetch(domain + "/delete_rows?session_id=" + Cookies.get("session_id"), {
      method: "POST",
      body: JSON.stringify({
        rows: rows,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        } else {
          return response.json();
        }
      })
      .then((result) => {
        this.setState({ deleted: result.deleted_count });
      })
      .catch((error) => {})
      .finally(() => {
        this.setState({
          selected: [],
        });
        this.retrieve_archive();
      });
  };

  mediaTypeChange = (event) => {
    const value = event.target.value;
    this.setState({
      media_type: value,
      preview_images: [],
      preview_videos: [],
      images_selected: [],
      videos_selected: [],
      date: new Date(),
    });

    Cookies.set("media_type", value, { expires: 7 });
  };

  showImages = (event) => {
    const newval = this.state.show_images === "true" ? "false" : "true";
    this.setState({
      show_images: newval,
    });
    Cookies.set("show_images", newval, { expires: 7 });
  };

  dateChange = (event) => {
    this.setState({ date: event });
  };

  onDrop = (media) => {
    const newMedia = [
      ...new Map(
        [...this.state.media, ...media]
          .filter(function (file) {
            return (
              !file.name.includes("/") &&
              (file.type.includes("image") || file.type.includes("video"))
            );
          })
          .map(function (file) {
            return [file.name + file.lastModified.toString(), file];
          })
      ).values(),
    ];
    this.setState({
      media: newMedia,
    });
    this.updateMedia(newMedia);
  };

  setClicked(clicked) {
    this.setState({ clicked: clicked });
  }

  removeMedia(i) {
    this.setState((state) => {
      const media = state.media.filter((e) => e.media !== i.media);
      let images = state.preview_images;
      let videos = state.preview_videos;
      if (i.img >= 0) {
        images = images.filter((e) => e.index.img !== i.img);
      } else if (i.vid >= 0) {
        videos = videos.filter((e) => e.index.vid !== i.vid);
      }
      return {
        media: media,
        preview_images: images,
        preview_videos: videos,
      };
    });
  }

  updateMedia(media) {
    let images = [];
    let videos = [];
    let img = 0;
    let vid = 0;
    media.forEach((file, i) => {
      if (file.type.includes("image")) {
        images.push({
          src: URL.createObjectURL(file),
          index: { media: i, img: img, vid: -1 },
        });
        img++;
      } else if (file.type.includes("video")) {
        videos.push({
          src: URL.createObjectURL(file),
          index: { media: i, img: -1, vid: vid },
        });
        vid++;
      }
    });
    this.setState({
      preview_images: images,
      preview_videos: videos,
    });
  }

  getSubHeaderComponent = () => {
    return (
      <>
        {this.state.authorized === "true" || this.state.authorized_view ? (
          <Form
            style={{ left: 0, position: "absolute" }}
            onSubmit={(e) => e.preventDefault()}
          >
            <Form.Group>
              <Container>
                <Row>
                  <Col md="auto">
                    <Form.Check
                      type="checkbox"
                      label="Recently Added"
                      id="recently added"
                      checked={this.state.no_sort}
                      onChange={this.noSort}
                    />
                    <Form.Check
                      type="checkbox"
                      label="Authorized View"
                      id="authorized views"
                      checked={this.state.authorized === "true"}
                      onChange={this.authorizedChange}
                    />
                  </Col>
                  <Col md="auto">
                    <Button
                      onClick={this.deleteRows}
                      style={
                        this.state.selected.selectedCount
                          ? {}
                          : { display: "none" }
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                        <path
                          fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        ></path>
                      </svg>{" "}
                      Delete Selected
                    </Button>
                    <Button
                      onClick={() => {
                        this.download("selected");
                      }}
                      style={
                        this.state.selected.selectedCount
                          ? {}
                          : { display: "none" }
                      }
                    >
                      Download Selected
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form.Group>
          </Form>
        ) : (
          ""
        )}
        <Form
          className="mt-2"
          style={{ right: 0, position: "absolute" }}
          onSubmit={(e) => e.preventDefault()}
        >
          <Form.Group>
            <Form.Control
              value={this.state.filterText}
              onChange={(e) => {
                // ADD SUPPPORT FOR FUZZY STRING SEARCH APPROXIMATE match
                // ESPECIALLY TWITTER
                this.setState((prevState) => {
                  return {
                    added: 0,
                    filterText: e.target.value,
                    filtered_archive: JSON.parse(
                      JSON.stringify(
                        prevState.archive.filter((row) => {
                          for (const [, v] of Object.entries(row)) {
                            if (
                              v
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                            ) {
                              return true;
                            }
                          }
                          return false;
                        })
                      )
                    ),
                  };
                });
              }}
              name="filter input"
              placeholder="Search"
              autocomplete="off"
            ></Form.Control>
          </Form.Group>
        </Form>
      </>
    );
  };

  customSort = (rows, selector, direction) => {
    if (this.state.no_sort) {
      this.setState({
        no_sort: false,
      });
    }
    const collator = new Intl.Collator("en", {
      numeric: true,
      sensitivity: "base",
    });
    const sorted = rows.sort((a, b) => {
      const fa = a.filename.split(".")[0].split("_");
      const fb = b.filename.split(".")[0].split("_");
      return collator.compare(
        a.date + a.media_type + a.url + fa[2],
        b.date + b.media_type + b.url + fb[2]
      );
    });
    return direction === "asc" ? sorted : sorted.reverse();
  };

  noSort = () => {
    this.setState((prevState) => {
      Cookies.set("no_sort", !prevState.no_sort ? "true" : "false", {
        expires: 1,
      });
      return {
        no_sort: !prevState.no_sort,
        added: 0,
      };
    });
  };

  authorizedChange = () => {
    this.setState((prevState) => {
      return {
        authorized: prevState.authorized === "true" ? "false" : "true",
      };
    });
  };

  groupChange = (event) => {
    Cookies.set("group", event.target.value);
    this.setState(
      {
        group: event.target.value,
        member: "group",
        displayButton: true,
        archive: [],
        filtered_archive: [],
        added: 0,
        deleted: 0,
        duplicate: 0,
        errors: 0,
      },
      // inefficient but for now guarantee results returned
      function () {
        this.retrieve_archive();
      }
    );
  };

  memberChange = (event) => {
    Cookies.set("member", event.target.value);
    this.setState(
      {
        member: event.target.value.toLowerCase(),
        displayButton: true,
        archive: [],
        filtered_archive: [],
        added: 0,
        deleted: 0,
        duplicate: 0,
        errors: 0,
      },
      function () {
        this.retrieve_archive();
      }
    );
  };

  handleSelect = (selected) => {
    if (this.state.authorized_view) {
      this.setState({
        selected: selected,
      });
    }
  };

  download = (preview_or_selected) => {
    this.setState({
      downloading: true,
    });

    const proxy = "https://stayc.onrender.com/";
    //const type = this.state.media_type;

    const urls = this.get_links(preview_or_selected);
    urls.forEach((item, i) => {
      const url = item.url;
      if (preview_or_selected === "preview") {
        let ext = "jpg";
        if (url.includes(".mp4")) {
          ext = "mp4";
        } else if (url.includes(".png")) {
          ext = "png";
        }
        let date = "";
        if (this.state.media_type == "other") {
          const mm = this.state.date.getMonth() + 1; // zero indexed
          const dd = this.state.date.getDate();
          date = [
            this.state.date.getFullYear(),
            " ",
            (mm > 9 ? "" : "0") + mm,
            (dd > 9 ? "" : "0") + dd,
          ].join("");
        } else {
          date =
            this.state.date.substring(0, 4) +
            " " +
            this.state.date.substring(4);
        }
        const fileStream = streamSaver.createWriteStream(
          `${date}_${item.index + 1}.${ext}`
        );
        // url must be CORS enabled
        fetch(proxy + url).then((res) => {
          const readableStream = res.body;

          if (i === urls.length - 1) {
            this.setState({
              downloading: false,
            });
          }

          // more optimized
          if (window.WritableStream && readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream);
          }

          window.writer = fileStream.getWriter();

          const reader = res.body.getReader();
          const pump = () => {
            reader.read().then((res) => {
              res.done
                ? window.writer.close()
                : window.writer.write(res.value).then(pump);
            });
          };
          pump();
        });
      } else {
        const fileStream = streamSaver.createWriteStream(
          item.filename.substring(0, 4) + " " + item.filename.substring(5)
        );
        // url must be CORS enabled
        fetch((url.includes("staycswith.com") ? "" : proxy) + url).then(
          (res) => {
            const readableStream = res.body;

            if (i === urls.length - 1) {
              this.setState({
                downloading: false,
              });
            }

            // more optimized
            if (window.WritableStream && readableStream.pipeTo) {
              return readableStream.pipeTo(fileStream);
            }

            window.writer = fileStream.getWriter();

            const reader = res.body.getReader();
            const pump = () => {
              reader.read().then((res) => {
                res.done
                  ? window.writer.close()
                  : window.writer.write(res.value).then(pump);
              });
            };
            pump();
          }
        );
      }
    });
  };

  download_links = () => {
    const date =
      this.state.date.substring(0, 4) + "_" + this.state.date.substring(4);
    const type = this.state.media_type;
    const member = this.state.member.toLowerCase();

    const urls = this.get_links();
    let list = [];
    urls.forEach((item) => {
      list.push(item.url);
    });
    const element = document.createElement("a");
    const file = new Blob([list.join("\n")], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${date}_${type}_${member}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  get_links = (preview_or_selected) => {
    // return all selected urls in original resolution format
    let urls = [];
    if (preview_or_selected === "preview") {
      this.state.images_selected.forEach((val, i) => {
        if (val) {
          urls.push({
            url: this.state.preview_images[i],
            index: i,
          });
        }
      });
      this.state.videos_selected.forEach((val, i) => {
        if (val) {
          urls.push({
            url: this.state.preview_videos[i],
            index: i,
          });
        }
      });
      const type = this.state.media_type;
      urls.forEach((item, i, arr) => {
        let url = item.url;
        if (type === "naver") {
          arr[i].url = url.split("?")[0];
        } else if (type === "twitter") {
          arr[i].url += "?format=jpg&name=orig";
        }
      });
      return urls;
    } else {
      const urls = this.state.selected.selectedRows.map((row) => {
        return {
          filename: row.filename,
          url:
            row.media_url ||
            "https://staycswith.com/get_image/" +
              row.filename +
              "?group=" +
              row.group +
              "&name=" +
              row.name +
              "&media_type=" +
              row.media_type +
              "&session_id=" +
              Cookies.get("session_id"),
        };
      });
      return urls;
    }
  };

  changeLayout = () => {
    this.setState({
      masonry: !this.state.masonry,
    });
  };

  changeDiscord = () => {
    const send_discord = this.state.send_discord === "true" ? "false" : "true";
    this.setState({
      send_discord: send_discord,
    });
    Cookies.set("send_discord", send_discord, { expires: 100 });
  };

  changeFansite = () => {
    const fansite = this.state.fansite === "true" ? "false" : "true";
    this.setState({
      fansite: fansite,
    });
  };

  descriptionChange = (event) => {
    this.setState({
      description: event.target.value,
    });
  };

  render() {
    if (Cookies.get("logged_in") == null) {
      return <Redirect to="/login" />;
    } else {
      if (window.location.pathname != "/archive") {
        window.history.replaceState("", "", "/archive");
      }
      const proxy =
        this.state.media_type === "instagram"
          ? "https://stayc.onrender.com/"
          : "";

      let s_archive = this.state.filtered_archive;

      // sort chronologically descending
      //&& this.state.unsorted to sort once
      if (this.state.masonry) {
        const collator = new Intl.Collator("en", {
          numeric: true,
          sensitivity: "base",
        });
        const sorted = s_archive.sort((a, b) => {
          const fa = a.filename.split(".")[0].split("_");
          const fb = b.filename.split(".")[0].split("_");
          return collator.compare(
            a.date + a.media_type + a.url + fa[2],
            b.date + b.media_type + b.url + fb[2]
          );
        });
        s_archive = sorted.reverse();
        //  this.setState({ unsorted: false, filtered_archive: s_archive }); // causes infinite loop, depth
      }

      const rows = s_archive.map((r, i) => {
        const newly_added = i < this.state.added;

        r["to_display"] = true;

        r["display_date"] = newly_added ? <b>{r.date + " (new)"}</b> : r.date;

        r["display_filename"] = newly_added ? <b>{r.filename}</b> : r.filename;

        r["display_url"] = (
          <div style={{ maxWidth: "35vw" }}>
            <a
              style={{
                fontWeight: newly_added ? "bold" : "normal",
                textOverflow: "ellipsis",
              }}
              target="_blank"
              rel="noopener noreferrer"
              href={r.url}
            >
              {r.url}
            </a>
          </div>
        );
        const dstyle = this.state.masonry
          ? { maxWidth: "17vw" }
          : { maxHeight: "15vh" };
        if (this.state.show_images === "true" && r.media_url !== "") {
          if (r.filename === "") {
            r["display_media_url"] = "User uploaded";
          } else if (
            r.filename.endsWith(".jpg") ||
            r.filename.endsWith(".jpeg") ||
            r.filename.endsWith(".png")
          ) {
            let temp = r.media_url;
            // if not viewer mode, make thumbnail
            if (r.media_type === "twitter") {
              temp = temp.replace("name=orig", "name=small");
            } else if (r.media_type === "naver") {
              temp += "?type=w1200";
            } else if (
              r.media_type === "weverse" &&
              r.media_url.startsWith("https://weverse")
            ) {
              temp += "?type=w1414";
            }
            const img = (
              <LazyLoadComponent>
                <img
                  style={dstyle}
                  src={
                    // if not secured, get through proxy
                    (temp.match("^http:") ||
                    temp.match("^https://photo.newsen.com/")
                      ? "https://stayc.onrender.com/"
                      : "") + temp
                  }
                  key={temp}
                  alt={r.filename}
                ></img>
              </LazyLoadComponent>
            );
            if (this.state.masonry) {
              r["display_media_url"] = img;
            } else {
              r["display_media_url"] = (
                <a href={r.media_url} target="_blank" rel="noopener noreferrer">
                  {img}
                </a>
              );
            }
          } else if (
            r.filename.endsWith(".mp4") ||
            r.filename.endsWith(".mov")
          ) {
            const vid = (
              <LazyLoadComponent>
                <video
                  style={dstyle}
                  src={r.media_url}
                  key={r.media_url}
                  alt={r.fileame}
                  playsInline
                  autoPlay
                  muted
                  loop
                ></video>
              </LazyLoadComponent>
            );
            if (this.state.masonry) {
              r["display_media_url"] = vid;
            } else {
              r["display_media_url"] = (
                <a href={r.media_url} target="_blank" rel="noopener noreferrer">
                  {vid}
                </a>
              );
            }
          } else {
            r["display_media_url"] =
              r.media_url === "" ? (
                "User Uploaded"
              ) : (
                <a
                  style={{ fontWeight: newly_added ? "bold" : "normal" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={r.media_url}
                >
                  Link
                </a>
              );
          }
        } else {
          if (
            r.media_type === "instagram" ||
            r.media_type === "facebook" ||
            r.media_type === "other"
          ) {
            const img_url =
              "/get_image/" +
              r.filename +
              "?group=" +
              r.group +
              "&name=" +
              r.name +
              "&media_type=" +
              r.media_type +
              "&session_id=" +
              Cookies.get("session_id");
            if (r.filename.endsWith(".mp4")) {
              r["display_media_url"] = (
                <a href={img_url} target="_blank" rel="noopener noreferrer">
                  {r.filename}
                </a>
              );
              r["to_display"] = false;
            } else {
              const img = (
                <LazyLoadComponent>
                  <img
                    style={dstyle}
                    src={img_url}
                    key={img_url}
                    alt={r.filename}
                  ></img>
                </LazyLoadComponent>
              );
              if (this.state.masonry) {
                r["display_media_url"] = img;
              } else {
                r["display_media_url"] = (
                  <a href={img_url} target="_blank" rel="noopener noreferrer">
                    {img}
                  </a>
                );
              }
            }
          } else {
            r["display_media_url"] =
              r.media_url === "" ? (
                "User Uploaded"
              ) : (
                <a
                  style={{ fontWeight: newly_added ? "bold" : "normal" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={r.media_url}
                >
                  Link
                </a>
              );
          }
        }
        return r;
      });

      let columns = [
        {
          name: "Date",
          selector: (row) => row.display_date,
          sortable: true,
          width: "auto",
        },
        {
          name: "Post URL",
          selector: (row) => row.display_url,
          sort: "asc",
          maxWidth: "30vw",
          grow: 3,
        },
        {
          name: "Media",
          selector: (row) => row.display_media_url,
          sort: "asc",
          maxWidth: "35vw",
          grow: 3,
        },
      ];

      const nottwitter_instagram_naver = this.auto_mt();
      const total_length =
        this.state.preview_images.length + this.state.preview_videos.length;

      /// USE REACT.MEMO
      const images = this.state.preview_images.map((url, i) => {
        if (this.state.media_type === "weverse") {
          url += "?type=w1414";
        }
        return (
          <Col style={{ paddingLeft: 0, paddingRight: 0, flexGrow: 0 }}>
            <img
              key={url}
              style={{
                maxWidth: Math.floor(
                  (window.innerWidth * 0.9) /
                    Math.min(Math.max(total_length, 4), 5)
                ),
                padding: 4,
                maxHeight: Math.floor(window.innerHeight / 2),
                border: this.state.images_selected[i]
                  ? "5px solid #FFFFFF"
                  : "none",
              }}
              src={proxy + url}
              onClick={() =>
                nottwitter_instagram_naver && !this.state.generic_preview
                  ? this.removeMedia(url.index)
                  : this.select(i)
              }
              alt={i}
            />
          </Col>
        );
      });

      const videos = this.state.preview_videos.map((url, i) => {
        return (
          <Col style={{ paddingLeft: 0, paddingRight: 0, flexGrow: 0 }}>
            <video
              key={url}
              style={{
                maxWidth: Math.floor(
                  (window.innerWidth * 0.9) /
                    Math.min(Math.max(total_length, 4), 5)
                ),
                padding: 4,
                maxHeight: Math.floor(window.innerHeight / 2),
                border: this.state.videos_selected[i]
                  ? "5px solid #FFFFFF"
                  : "none",
              }}
              src={proxy + url}
              onClick={() =>
                nottwitter_instagram_naver && !this.state.generic_preview
                  ? this.removeMedia(url.index)
                  : this.v_select(i)
              }
              alt={i}
              playsInline
              autoPlay
              muted
              loop
            />
          </Col>
        );
      });

      const media = images.concat(videos);

      // making link textbox text label

      let media_type = this.state.media_type;
      if (media_type === "other") {
        media_type = "Source";
      } else {
        if (media_type === "twitter") {
          media_type = "Tweet";
        } else if (media_type === "instagram") {
          media_type = "Instagram";
        } else if (media_type === "naver") {
          media_type = "Naver";
        } else if (media_type === "sbs") {
          media_type = "Sbs";
        } else if (media_type === "weverse") {
          media_type = "Weverse";
        } else if (media_type === "fancafe") {
          media_type = "Fancafe";
        } else if (media_type === "universe") {
          media_type = "Universe";
        } else if (media_type === "youtube") {
          media_type = "Youtube";
        } else if (media_type === "facebook") {
          media_type = "Facebook";
        } else {
          media_type = "invalid source";
        }
        media_type += " URL";
      }

      const select_all_text = this.state.images_selected
        .concat(this.state.videos_selected)
        .every((v) => v === 1)
        ? "Clear"
        : "Select All";

      const media_preview_text = this.state.media.length
        ? nottwitter_instagram_naver
          ? "Click to remove images"
          : "Click to select images"
        : "";
      let added = this.state.added;

      added = added
        ? "Added " + added + (added > 1 ? " files." : " file.")
        : "";

      let deleted = this.state.deleted;

      deleted = deleted
        ? "Deleted " + deleted + (deleted > 1 ? " files." : " file.")
        : "";

      let duplicate = this.state.duplicate;

      duplicate = duplicate
        ? "Found " +
          duplicate +
          " duplicate " +
          (duplicate > 1 ? "files." : "file.")
        : "";

      let errors = this.state.errors;

      errors = errors
        ? "Encountered " + errors + (errors > 1 ? " errors." : " error.")
        : "";

      const groups_options = Object.keys(JSON.parse(JSON.stringify(groups_master))).map((group, i) => {
        return <option key={group}>{group.toUpperCase()}</option>;
      });

      let groups = JSON.parse(JSON.stringify(groups_master));
      Object.keys(groups).forEach((group, i) => {
        groups[group] = groups[group].map((member, j) => {
          return <option key={group + member}>{member}</option>;
        });
      });

      const download_buttons =
        this.state.preview_images.length + this.state.preview_videos.length ? (
          <>
            <Button
              className="btn-icon img-fluid mb-4 mt-2"
              color="primary"
              onClick={() => {
                this.download("preview");
              }}
              disabled={
                this.state.downloading ||
                !this.state.images_selected
                  .concat(this.state.videos_selected)
                  .some((v) => v === 1)
              }
            >
              {this.state.downloading ? (
                <div>
                  Downloading <CircularProgress height="20px" color="#FFFFFF" />
                </div>
              ) : (
                "Download"
              )}
            </Button>
            {/*<Button
              className="btn-icon img-fluid mb-4 mt-2"
              color="primary"
              onClick={this.download_links}
              disabled={
                !this.state.images_selected
                  .concat(this.state.videos_selected)
                  .some((v) => v === 1)
              }
            >
              Download Links
            </Button>*/}
          </>
        ) : (
          ""
        );

      const submit_text =
        "Submit" +
        (this.state.member.toLowerCase() === "choose a member"
          ? ""
          : " (" + this.state.member + ")");

      return (
        <>
          <SimpleNavbar />
          <main ref="main">
            <div className="position-relative">
              <section className="section" style={{ minHeight: "100vh" }}>
                <Container
                  style={{
                    maxWidth: this.state.width > 1000 ? "70vw" : "100vw",
                  }}
                  className="mb-3"
                >
                  <h2 style={{ color: "white" }}>Archive</h2>
                  <Form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      if (
                        !(
                          this.state.preview_images.length +
                            this.state.preview_videos.length ||
                          nottwitter_instagram_naver ||
                          this.state.is_disable ||
                          this.state.link === ""
                        )
                      ) {
                        await this.click_preview(e);
                      }
                    }}
                  >
                    <Form.Row>
                      <Form.Group as={Col} lg={4} md={5} sm={6} xs={6}>
                        <Form.Label>Group</Form.Label>
                        <Form.Control
                          as="select"
                          value={this.state.group}
                          onChange={this.groupChange}
                          name={"group"}
                        >
                          {groups_options}
                        </Form.Control>
                      </Form.Group>
                      {this.state.group.toLowerCase() in groups_master ? (
                        <Form.Group as={Col} lg={4} md={5} sm={6} xs={6}>
                          <Form.Label>Member</Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.member}
                            onChange={this.memberChange}
                            name={"member"}
                          >
                            {groups[this.state.group.toLowerCase()]}
                          </Form.Control>
                        </Form.Group>
                      ) : (
                        ""
                      )}
                    </Form.Row>
                    {this.state.authorized === "true" ? (
                      <>
                        {this.state.link ? (
                          <>
                            <Form.Row>
                              <Form.Group as={Col} lg={2} md={5} sm={6} xs={6}>
                                <Form.Label>Post Source</Form.Label>
                                <Form.Control
                                  as="select"
                                  value={this.state.media_type}
                                  onChange={this.mediaTypeChange}
                                >
                                  <option value="">Choose media source</option>
                                  <option value="twitter">Twitter</option>
                                  <option value="instagram">Instagram</option>
                                  <option value="naver">Naver</option>
                                  <option value="sbs">SBS</option>
                                  <option value="weverse">Weverse</option>
                                  <option value="melon">Melon</option>
                                  <option value="dispatch">Dispatch</option>
                                  <option value="osen">Osen</option>
                                  <option value="other">Other</option>
                                </Form.Control>
                              </Form.Group>
                              {["stayc", "ive"].includes(
                                this.state.group.toLowerCase()
                              ) ? (
                                <Form.Group as={Col} lg={2} md={5} m={6} xs={6}>
                                  <Form.Label>Options</Form.Label>
                                  <Form.Check
                                    type="checkbox"
                                    label="Send in Discord"
                                    id="send in discord"
                                    checked={this.state.send_discord === "true"}
                                    onChange={this.changeDiscord}
                                  />
                                  <Form.Check
                                    type="checkbox"
                                    label="Fansite"
                                    id="fansite"
                                    checked={this.state.fansite === "true"}
                                    onChange={this.changeFansite}
                                  />
                                </Form.Group>
                              ) : (
                                ""
                              )}
                            </Form.Row>
                          </>
                        ) : (
                          ""
                        )}
                        <Form.Row>
                          <Form.Group as={Col} lg={8} md={8} m={10} xs={12}>
                            <Form.Label>Post URL (required)</Form.Label>
                            <Form.Control
                              value={this.state.link}
                              onChange={this.linkChange}
                              name={"link"}
                              autocomplete="off"
                            ></Form.Control>
                          </Form.Group>
                          {this.state.link ? (
                            <Form.Group as={Col} lg={8} md={8} m={10} xs={12}>
                              <Form.Label>
                                Description (optional, eg RUN2U MV Behind)
                              </Form.Label>
                              <Form.Control
                                value={this.state.description}
                                onChange={this.descriptionChange}
                                name={"description"}
                                autocomplete="off"
                              ></Form.Control>
                            </Form.Group>
                          ) : (
                            ""
                          )}
                        </Form.Row>
                        {nottwitter_instagram_naver && this.state.link ? (
                          <>
                            <Form.Group className="mb-3" controlId="imageurls">
                              <Form.Label>
                                Media URLs - one per line (optional)
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={4}
                                value={this.state.media_urls}
                                onChange={this.mediaChange}
                                name={"media_urls"}
                                autocomplete="off"
                              />
                              <Form.Label className="mt-3">
                                Media Upload (optional)
                              </Form.Label>
                              <Dropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div
                                      {...getRootProps({
                                        className: "dropzone",
                                      })}
                                      style={{
                                        borderColor: this.state.clicked
                                          ? "#2990ff"
                                          : "#eeeeee",
                                      }}
                                      disabled={this.state.uploading}
                                      onMouseEnter={() => this.setClicked(true)}
                                      onMouseLeave={() =>
                                        this.setClicked(false)
                                      }
                                    >
                                      <input {...getInputProps()} />
                                      <br />
                                      <p>
                                        Drop files here, or click to select
                                        files
                                      </p>
                                      <br />
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                              <Form.Label className="mt-3">
                                Post Date (MM/DD/YYYY)
                              </Form.Label>
                              <DatePicker
                                selected={
                                  this.state.date instanceof Date
                                    ? this.state.date
                                    : new Date()
                                }
                                onChange={this.dateChange}
                                maxDate={
                                  new Date(new Date().getTime() + 86400000 * 1)
                                }
                              />
                            </Form.Group>
                          </>
                        ) : (
                          ""
                        )}
                        {media_preview_text}
                        <div style={{ color: "green", fontWeight: "bold" }}>
                          {added}
                        </div>
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          {deleted}
                        </div>
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          {duplicate}
                        </div>
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          {errors}
                        </div>
                        <Container style={{ marginLeft: 0 }}>
                          <Row className="justify-content-md-left">
                            <Button
                              className="btn-icon img-fluid mb-4 mt-2"
                              color="primary"
                              onClick={this.click}
                              disabled={
                                this.state.is_disable ||
                                !(
                                  (this.state.preview_images.length +
                                    this.state.preview_videos.length &&
                                    this.state.images_selected
                                      .concat(this.state.videos_selected)
                                      .some((v) => v === 1)) ||
                                  this.state.media.length ||
                                  this.state.media_urls
                                ) ||
                                this.state.link === "" ||
                                this.state.member.toLowerCase() ===
                                  "choose a member"
                              }
                            >
                              {this.state.is_disable ? (
                                <div>
                                  Please wait{" "}
                                  <CircularProgress
                                    height="20px"
                                    color="#FFFFFF"
                                  />
                                </div>
                              ) : (
                                submit_text
                              )}
                            </Button>
                            {this.state.preview_images.length +
                            this.state.preview_videos.length ? (
                              ""
                            ) : (
                              <Button
                                className="btn-icon img-fluid mb-4 mt-2"
                                color="primary"
                                onClick={async (e) => {
                                  await this.click_preview(e);
                                }}
                                disabled={
                                  this.state.is_disable ||
                                  this.state.link === ""
                                }
                              >
                                Preview
                              </Button>
                            )}
                            {this.state.media_type === "other" ? (
                              <Button
                                className="btn-icon img-fluid mb-4 mt-2"
                                color="primary"
                                onClick={async (e) => {
                                  await this.click_preview(e, true);
                                }}
                                disabled={
                                  this.state.is_disable ||
                                  this.state.link === ""
                                }
                              >
                                Preview
                              </Button>
                            ) : (
                              ""
                            )}
                            {this.state.preview_images.length +
                              this.state.preview_videos.length >
                              1 && !nottwitter_instagram_naver ? (
                              <Button
                                className="btn-icon img-fluid mb-4 mt-2"
                                color="primary"
                                onClick={this.select_all}
                                disabled={this.state.is_disable}
                              >
                                {select_all_text}
                              </Button>
                            ) : (
                              ""
                            )}
                            {download_buttons}
                          </Row>
                        </Container>
                        <Container
                          style={{
                            maxWidth:
                              this.state.width > 1000 ? "90vw" : "100vw",
                          }}
                        >
                          <Row
                            className="justify-content-md-center"
                            style={
                              this.state.preview_images.length +
                                this.state.preview_videos.length >=
                              5
                                ? {}
                                : { flexWrap: "nowrap" }
                            }
                          >
                            {media}
                          </Row>
                        </Container>
                        {this.state.preview_images.length +
                          this.state.preview_videos.length >
                        6 ? (
                          <>
                            <div style={{ color: "green", fontWeight: "bold" }}>
                              {added}
                            </div>
                            <div style={{ color: "red", fontWeight: "bold" }}>
                              {deleted}
                            </div>
                            <div style={{ color: "red", fontWeight: "bold" }}>
                              {duplicate}
                            </div>
                            <div style={{ color: "red", fontWeight: "bold" }}>
                              {errors}
                            </div>
                            <Container style={{ marginLeft: 0 }}>
                              <Row className="justify-content-md-left">
                                <Button
                                  className="btn-icon img-fluid mb-4 mt-2"
                                  color="primary"
                                  onClick={this.click}
                                  disabled={
                                    this.state.is_disable ||
                                    !(
                                      (this.state.preview_images.length +
                                        this.state.preview_videos.length &&
                                        this.state.images_selected
                                          .concat(this.state.videos_selected)
                                          .some((v) => v === 1)) ||
                                      this.state.media.length ||
                                      this.state.media_urls
                                    ) ||
                                    this.state.link === "" ||
                                    this.state.member.toLowerCase() ===
                                      "choose a member"
                                  }
                                >
                                  {this.state.is_disable ? (
                                    <div>
                                      Please wait{" "}
                                      <CircularProgress
                                        height="20px"
                                        color="#FFFFFF"
                                      />
                                    </div>
                                  ) : (
                                    submit_text
                                  )}
                                </Button>
                                {this.state.preview_images.length +
                                  this.state.preview_videos.length >
                                  1 && !nottwitter_instagram_naver ? (
                                  <Button
                                    className="btn-icon img-fluid mb-4 mt-2"
                                    color="primary"
                                    onClick={this.select_all}
                                    disabled={this.state.is_disable}
                                  >
                                    {select_all_text}
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {download_buttons}
                              </Row>
                            </Container>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.masonry}
                              onChange={this.changeLayout}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Grid (beta)"
                        />
                      </FormGroup>
                    )}
                  </Form>
                </Container>
                <Container
                  style={{
                    maxWidth: this.state.width > 1000 ? "90vw" : "100vw",
                  }}
                >
                  {this.state.masonry ? (
                    <Masonry
                      key={
                        this.state.no_sort +
                        this.state.authorized +
                        this.state.member +
                        this.state.group
                      }
                      columnCount={5}
                      rowGutter={5}
                      overscanBy={Infinity}
                      items={rows}
                      itemKey={(data) =>
                        data.filename + data.group + data.name + data.media_type
                      }
                      render={MasonryItem}
                    ></Masonry>
                  ) : (
                    <DataTable
                      key={
                        this.state.no_sort +
                        this.state.authorized +
                        this.state.member +
                        this.state.group
                      }
                      columns={columns}
                      data={rows}
                      theme="dark"
                      striped
                      responsive
                      pagination
                      paginationComponentOptions={paginationComponentOptions}
                      paginationResetDefaultPage={
                        this.state.resetPaginationToggle
                      }
                      selectableRows={this.state.authorized === "true"}
                      selectableRowsVisibleOnly={true}
                      selectableRowsNoSelectAll={true}
                      onSelectedRowsChange={this.handleSelect}
                      subHeader
                      subHeaderComponent={this.getSubHeaderComponent()}
                      persistTableHead
                      progressPending={this.state.loading}
                      customStyles={customStyles}
                      sortFunction={this.customSort}
                      defaultSortFieldId={
                        this.state.added || this.state.no_sort ? undefined : 1
                      }
                      defaultSortAsc={false}
                      noDataComponent={
                        <div style={{ padding: "24px" }}>
                          {this.state.member.toLowerCase() === "choose a member"
                            ? "Please choose a member."
                            : "There are no records to display."}
                        </div>
                      }
                      paginationRowsPerPageOptions={[10, 50, 100, 200]}
                    />
                  )}
                </Container>
              </section>
            </div>
          </main>
          <SimpleFooter />
        </>
      );
    }
  }
}

export default Archive;
