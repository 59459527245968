import React from "react";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { Row, Button } from "react-bootstrap";
import LogRocket from "logrocket";

import "assets/css/global.css";

import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

const domain =
  process.env.REACT_APP_REL === "TRUE" ? "" : "https://staycswith.com";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const state = urlParams.get("state");
    const code = urlParams.get("code");
    const error = urlParams.get("error");
    this.state = {
      state: state,
      code: code,
      reject: error === "access_denied",
      error: false,
      finished: false,
    };
  }

  componentDidMount() {
    if (this.state.state == null) {
      const requestOptions = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        mode: "cors",
      };
      fetch(
        domain + "/generateurl?session_id=" + Cookies.get("session_id"),
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.status);
          } else {
            return response.json();
          }
        })
        .then((result) => {
          window.location.href = result.url;
        })
        .catch((error) => {
          this.setState({
            error: true,
          });
        });
    } else if (!this.state.reject) {
      const request = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          code: this.state.code,
          state: this.state.state,
        }),
        mode: "cors",
      };
      fetch(
        domain + "/authorize?session_id=" + Cookies.get("session_id"),
        request
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.status);
          } else {
            return response.json();
          }
        })
        .then((result) => {
          LogRocket.identify(result.user_id, {
            username: result.username,
          });
          Cookies.set("user_id", result.user_id, { expires: 100 });
          Cookies.set("username", result.username, { expires: 100 });
          Cookies.set("logged_in", result.username, { expires: 100 });
          if (result.authorized === 1) {
            Cookies.set("authorized", "true", { expires: 100 });
          } else {
            Cookies.remove("authorized");
          }
          this.setState({
            finished: true,
          });
        })
        .catch((error) => {
          Cookies.remove("session_id");
          Cookies.remove("username");
          Cookies.remove("logged_in");
          Cookies.remove("authorized");
          this.setState({
            error: true,
          });
        });
    }
  }

  render() {
    if (this.state.reject) {
      return <Redirect to="/"> </Redirect>;
    }
    if (this.state.error && !this.state.reject) {
      return (
        <>
          <SimpleNavbar />
          <main ref="main">
            <div className="position-relative">
              <section className="section" style={{ height: "100vh" }}>
                <div className="sr-root">
                  <div className="sr-main">
                    <h2
                      style={{ color: "white" }}
                      className="mt-3 align-center"
                    >
                      Discord Login
                    </h2>
                    <div>
                      There was an error verifying your login. Try logging in
                      again below or reopen the page.
                    </div>
                    <br />
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = "/login";
                      }}
                    >
                      Log in
                    </Button>
                    <Row className="justify-content-md-center mb-4"></Row>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </>
      );
    }
    if (!this.state.finished && this.state.state !== null) {
      return (
        <>
          <main ref="main">
            <div className="position-relative">
              <section className="section" style={{ height: "100vh" }}>
                <div className="sr-root">
                  <div className="sr-main">
                    <h1
                      style={{ color: "white" }}
                      className="mt-3 align-center"
                    >
                      Authenticating login...
                    </h1>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </>
      );
    }
    if (!this.state.finished) {
      return null;
    }
    const previouspage = Cookies.get("return");
    const redirect_to = previouspage !== "/login" ? previouspage : "/";

    return <Redirect to={redirect_to}> </Redirect>;
  }
}
