import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";

const setTitle = (title) => {
  const prevTitle = document.title;
  document.title = title;
  return () => (document.title = prevTitle);
};

export default function Logout() {
  useEffect(() => setTitle("Logout Page"), []);

  Cookies.remove("logged_in");
  Cookies.remove("session_id");
  Cookies.remove("control_panel");

  Cookies.remove("username");
  Cookies.remove("return");

  return <Redirect to={"/"}></Redirect>;
}
