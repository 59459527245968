export const groups = {
  stayc: ["sumin", "sieun", "isa", "seeun", "yoon", "j", "group"],
  weeekly: ["soojin", "monday", "jihan", "jiyoon", "soeun", "zoa", "jaehee", "group"],
  everglow: ["eu", "sihyeon", "mia", "onda", "aisha", "yiren", "group"],
  aespa: ["karina", "giselle", "winter", "ningning", "group"],
  ive: ["gaeul", "yujin", "rei", "wonyoung", "liz", "leeseo", "group"],
  kep1er: ["yujin", "xiaoting", "mashiro", "chaehyun", "dayeon", "hikaru", "bahiyyih", "youngeun", "yeseo", "group"],
  rcpc: ["juri", "yeonhee", "suyun", "yunkyoung", "sohee", "dahyun", "group"],
  lightsum: ["sangah", "chowon", "nayoung", "hina", "juhyeon", "yujeong", "huiyeon", "jian", "group"],
  cherrybullet: ["haeyoon", "yuju", "bora", "jiwon", "remi", "chaerin", "may", "group"],
  dreamcatcher: ["jiu", "sua", "siyeon", "handong", "yoohyeon", "dami", "gahyeon", "group"],
  fromis9: ["saerom", "hayoung", "gyuri", "jiwon", "jisun", "soeyeon", "chaeyoung", "nakyung", "jiheon", "group"],
  wooah: ["nana", "wooyeon", "sora", "lucy", "minseo", "group"],
  purplekiss: ["jieun", "goeun", "dosie", "ireh", "yuki", "chaein", "swan", "group"],
  billlie: ["sua", "suhyeon", "haram", "tsuki", "sheon", "siyoon", "haruna", "group"],
  wjsn: ["bona", "dawon", "dayoung", "eunseo", "exy", "luda", "seola", "soobin", "yeonjung", "yeoreum", "group"],
  loona: ["heejin", "hyunjin", "haseul", "yeojin", "vivi", "kimlip", "jinsoul", "choerry", "yves", "chuu", "gowon", "oliviahye", "group"],
  viviz: ["eunha", "sinb", "umji", "group"],
  izone: ["wonyoung", "sakura", "yuri", "yena", "yujin", "nako", "eunbi", "hyewon", "hitomi", "chaewon", "minju", "chaeyeon", "group"],
  ohmygirl: ["hyojung", "mimi", "yooa", "seunghee", "jiho", "yubin", "arin", "group"],
  gidle: ["miyeon", "minnie", "soojin", "soyeon", "yuqi", "shuhua", "group"],
  blackpink: ["jisoo", "jennie", "rose", "lisa", "group"],
  gfriend: ["eunha", "sinb", "sowon", "umji", "yerin", "yuju", "group"],
  nmixx: ["lily", "haewon", "sullyoon", "jinni", "bae", "jiwoo", "kyujin", "group"],
  lesserafim: ["sakura", "chaewon", "yunjin", "kazuha", "garam", "eunchae", "group"],
};
