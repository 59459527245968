import React from "react";

// reactstrap components
import { Button, Container, Col } from "reactstrap";

import "../../assets/css/global.css";
// core components
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { Form } from "react-bootstrap";
import CircularProgress from "react-cssfx-loading/lib/CircularProgress";
import SimpleNavbar from "components/Navbars/SimpleNavbar.js";

import { LazyLoadComponent } from "react-lazy-load-image-component";

import { Masonry } from "masonic";

import { groups } from "../../assets/groups.js";

const group_options = Object.keys(groups).map((group, i) => {
  return <option key={group}>{group}</option>;
});

const MasonryItem = ({ data: { url, display_media_url } }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {display_media_url}
    </a>
  );
};

const domain =
  process.env.REACT_APP_REL === "TRUE" ? "" : "https://staycswith.com";



const colors = [
  "Black",
  "White",
  "Gray",
  "Red",
  "Pink",
  "Orange",
  "Brown",
  "Yellow",
  "Green",
  "Blue",
  "Purple",
];

class Fmasonry extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.time !== nextProps.time;
  }
  render() {
    return (
      <Masonry
        key={this.props.rows}
        columnCount={5}
        rowGutter={5}
        overscanBy={Infinity}
        items={this.props.rows}
        itemKey={(data) => data.url + data.media_url}
        render={MasonryItem}
      ></Masonry>
    );
  }
}

class ImageBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isDisable: false,
      done: false,
      group: "stayc",
      member: new Array(7).fill(0),
      time: Date.now(),
      rows: [],
      first: true,
      width: 0,
      height: 0,
      color: "",
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    if (this.state.first) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState({
        first: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (
      this.state.height > this.state.width !==
      window.innerHeight > window.innerWidth
    ) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  groupChange = (event) => {
    this.setState({
      group: event.target.value,
      member: new Array(groups[event.target.value].length).fill(0),
    });
  };

  memberChange = (i) => {
    let member = [...this.state.member];
    if (this.state.group === "stayc") {
      member[i] = 1 - member[i];
    } else {
      member = member.fill(0);
      member[i] = 1;
    }
    this.setState({
      member: member,
      isDisable: false,
    });
  };

  colorChange = (color) => {
    this.setState({
      color: this.state.color === color ? "" : color,
    });
  };

  click = () => {
    const { group, member, color } = this.state;
    this.setState({
      isDisable: true,
    });
    fetch(domain + "/querybrowse", {
      method: "POST",
      body: JSON.stringify({
        group: group,
        member: member,
        color: color,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        } else {
          return response.json();
        }
      })
      .then((result) => {
        this.setState({
          rows: result.rows.map((r, i) => {
            let temp = r.media_url;
            // if not viewer mode, make thumbnail
            if (r.media_type === "twitter") {
              temp = temp
                .replace("name=orig", "name=small")
                .replace(":orig", ":small");
            } else if (r.media_type === "naver") {
              temp += "?type=w1200";
            }
            r["display_media_url"] = (
              <LazyLoadComponent>
                <img
                  style={{ maxWidth: "17vw" }}
                  src={
                    // if not secured, get through proxy
                    (temp.match("^http:") ||
                    temp.match("^https://photo.newsen.com/")
                      ? "https://stayc.onrender.com"
                      : "") + temp
                  }
                  key={temp}
                  alt={r.url}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "";
                    currentTarget.alt = "";
                  }}
                ></img>
              </LazyLoadComponent>
            );
            return r;
          }),
          isDisable: false,
          done: true,
          time: Date.now(),
        });
      })
      .catch((result) => {
        this.setState({
          isDisable: false,
        });
      });
  };

  render() {
    const { group } = this.state;
    const stayc = group === "stayc";

    const member_options = groups[group].map((member, i) => {
      return (
        <Form.Check
          inline
          checked={this.state.member[i]}
          onChange={() => this.memberChange(i)}
          label={member}
          name={i}
          type={stayc ? "checkbox" : "radio"}
          id={group + member}
        />
      );
    });

    const color_options = colors.map((color, i) => {
      return (
        <Form.Check
          inline
          checked={this.state.color === color}
          onChange={() => this.colorChange(color)}
          label={color}
          name={i}
          type={"checkbox"}
          id={color}
        />
      );
    });

    return (
      <>
        <SimpleNavbar />
        <main ref="main">
          <div className="position-relative">
            <section
              className="section"
              style={{
                minHeight: "100vh",
              }}
            >
              <Container
                style={{
                  maxWidth: this.state.width > 1000 ? "70vw" : "100vw",
                }}
                className="mb-3"
              >
                <h2
                  style={{
                    color: "white",
                  }}
                >
                  Image Browser
                </h2>
                <Form className="mt-2">
                  <Form.Row>
                    <Form.Group as={Col} lg={4} md={5} sm={6} xs={6}>
                      <Form.Label>Choose a Group</Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.group}
                        onChange={this.groupChange}
                        name={"group"}
                      >
                        {group_options}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group>
                      <Form.Label>Choose Member{stayc ? "(s)" : ""}</Form.Label>
                      <div>{member_options}</div>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    {stayc &&
                    this.state.member.reduce((a, c) => a + c) === 1 &&
                    this.state.member[this.state.member.length - 1] !== 1 ? (
                      <Form.Group>
                        <Form.Label>Choose Color (optional)</Form.Label>
                        <div>{color_options}</div>
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </Form.Row>
                </Form>
                <div>
                  {this.state.member.some((e) => e === 1) ? (
                    <Button
                      className="btn-icon img-fluid"
                      color="primary"
                      onClick={this.click}
                      disabled={this.state.isDisable}
                    >
                      {this.state.isDisable ? (
                        <div>
                          Please Wait
                          <CircularProgress height="20px" color="#FFFFFF" />
                        </div>
                      ) : (
                        "View"
                      )}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
                <br />
              </Container>
              <Container
                style={{
                  maxWidth: this.state.width > 1000 ? "90vw" : "100vw",
                }}
              >
                {this.state.done ? (
                  <Fmasonry rows={this.state.rows} time={this.state.time} />
                ) : (
                  ""
                )}
              </Container>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ImageBrowser;
