export const ive_memes = [
  {
    title: "Wony - Spotify",
    url:
      "https://cdn.discordapp.com/attachments/890712623279140914/997618161765601351/wonyoung_spotify.png",
    type: "image",
  },
  {
    title: "Wony - News Anchor",
    url:
      "https://cdn.discordapp.com/attachments/890712623279140914/997848421362827394/wonyoung_news.mp4",
    type: "video",
  },
];
