import React from "react";

import { slide as Menu } from "react-burger-menu";

import { Form, Button } from "react-bootstrap";

import Cookies from "js-cookie";

const domain =
  process.env.REACT_APP_REL === "TRUE" ? "" : "https://staycswith.com";

var styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "24px",
    top: "24px",
  },
  bmBurgerBars: {
    background: "#FFFFFF",
    height: "1.8px",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    position: "fixed",
    height: "48px",
    width: "48px",
    right: "15px",
    top: "15px",
  },
  bmCross: {
    background: "#bdc3c7",
    height: "36px",
    right: "5px",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#FFFFFF",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
    textDecoration: "none",
    color: "#d1d1d1",
    transition: "color 0.2",
    alignContent: "right",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};

const langs = [
  "English",
  "Malay",
  "Tagalog",
  "Italian",
  "Greek",
  "中文（繁體）- coming soon",
  "Spanish - coming soon",
];

function determineLang() {
  const lang = Cookies.get("lang");
  if (lang != null || lang in langs) {
    return lang;
  }
  switch (
    window.navigator.languages && window.navigator.languages.length
      ? window.navigator.languages[0]
      : window.navigator.language
  ) {
    case "en-US":
      return "English";
    case "ms":
      return "Malay";
    case "it":
      return "Italian";
    case "tl":
      return "Tagalog";
    case "tl-PH":
      return "Tagalog";
    case "el":
      return "Greek";
    default:
      return "English";
  }
}

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    const lang = determineLang();
    const interactive = Cookies.get("interactive") === "1" ? 1 : 0;
    this.state = {
      val: Cookies.get("logged_in"),
      username: Cookies.get("username"),
      lang: lang,
      interactive: interactive,
    };
    Cookies.set("lang", lang, { expires: 365 });
    Cookies.set("interactive", interactive, { expires: 365 });
  }
  render() {
    const languages = langs.map((item) => <option key={item}>{item}</option>);
    return (
      <>
        <Menu styles={styles} right className="text-center">
          {this.state.val == null ? (
            <div className="text-center">
              <p className="text-center" style={{ color: "black" }}>
                Log in below to access protected pages.
              </p>
              <br />
              <Button
                alt="Login Button"
                onClick={() => {
                  window.location.href = domain + "/login";
                }}
                className="btn-neutral btn-icon mt-0"
                color="default"
              >
                <span className="btn-inner--icon">
                  <img
                    src={require("assets/img/brand/discord_single.svg")}
                    alt="discord logo"
                  />
                </span>
                <span
                  className="nav-link-inner--text ml-1"
                  style={{ color: "#7289DA" }}
                >
                  Discord Login
                </span>
              </Button>
            </div>
          ) : (
            <div className="text-center">
              <p className="text-center" style={{ color: "black" }}>
                Hi, {this.state.username}! You have access to protected pages.
              </p>
              <br />
              <Button
                alt="Logout Button"
                onClick={() => {
                  window.location.href = domain + "/logout";
                }}
                className="btn-neutral btn-icon mt-0"
                color="default"
              >
                <span className="btn-inner--icon">
                  <img
                    src={require("assets/img/brand/discord_single.svg")}
                    alt="discord logo"
                  />
                </span>
                <span
                  className="nav-link-inner--text ml-1"
                  style={{ color: "#7289DA" }}
                >
                  Log Out
                </span>
              </Button>
            </div>
          )}
          <Form className="mt-4">
            <Form.Group controlId="x">
              <Form.Label style={{ color: "black" }}>
                Preferred Language
              </Form.Label>
              <Form.Control
                as="select"
                value={this.state.lang}
                onChange={(event) => {
                  this.setState({
                    lang: event.target.value,
                  });
                  this.props.langChange(event.target.value);
                  Cookies.set("lang", event.target.value, { expires: 365 });
                }}
              >
                {languages}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="y">
              <Form.Label style={{ color: "black" }}>
                Interactive Mode
              </Form.Label>
              <Form.Check
                type="switch"
                style={{ color: "black" }}
                id="custom-switch"
                label="Click to toggle"
                checked={this.state.interactive}
                onChange={(event) => {
                  const on = this.state.interactive ? 0 : 1;
                  this.setState({
                    interactive: on,
                  });
                  this.props.interactiveChange(on);
                  Cookies.set("interactive", on, { expires: 365 });
                }}
              ></Form.Check>
            </Form.Group>
            <div className="text-center">
              <p className="text-center" style={{ color: "black" }}>
                Click on a Link Below
              </p>
              <br />
              <Button
                onClick={() => {
                  window.location.href = domain + "/archive";
                }}
                className="btn-neutral btn-icon mt-0 btn-block"
                color="default"
              >
                Archive
              </Button>
              <Button
                onClick={() => {
                  window.location.href = domain + "/img";
                }}
                className="btn-neutral btn-icon mt-0 btn-block"
                color="default"
              >
                Search
              </Button>
              {/*<Button
                onClick={() => {
                  window.location.href = domain + "/browse";
                }}
                className="btn-neutral btn-icon mt-0 btn-block"
                color="default"
              >
                Browser
              </Button>*/}
              <Button
                onClick={() => {
                  window.location.href = domain + "/j";
                }}
                className="btn-neutral btn-icon mt-0 btn-block"
                color="default"
              >
                J Compilation
              </Button>
              <Button
                onClick={() => {
                  window.location.href = domain + "/isa";
                }}
                className="btn-neutral btn-icon mt-0 btn-block"
                color="default"
              >
                ISA Compilation
              </Button>
            </div>
          </Form>
        </Menu>
      </>
    );
  }
}
export default SideBar;
