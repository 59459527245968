import React from "react";

import Cookies from "js-cookie";
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import SideBar from "components/Navbars/SideBar.js";
import "assets/css/slick_temp.css";

const langs = [
  "English",
  "Malay",
  "Tagalog",
  "Italian",
  "Greek",
  "中文（繁體）- coming soon",
  "Spanish - coming soon",
];

function determineLang() {
  const lang = Cookies.get("lang");
  if (lang != null || lang in langs) {
    return lang;
  }
  switch (
    window.navigator.languages && window.navigator.languages.length
      ? window.navigator.languages[0]
      : window.navigator.language
  ) {
    case "en-US":
      return "English";
    case "ms":
      return "Malay";
    case "it":
      return "Italian";
    case "tl":
      return "Tagalog";
    case "tl-PH":
      return "Tagalog";
    case "el":
      return "Greek";
    default:
      return "English";
  }
}

class SocialMedia extends React.Component {
  constructor(props) {
    super(props);
    const lang = determineLang();
    this.state = {
      val: Cookies.get("logged_in"),
      username: Cookies.get("username"),
      hover: [0, 0, 0, 0, 0, 0],
      disc: [0, 0],
      menuOpen: false,
      width: 0,
      height: 0,
      initialSet: false,
      lang: lang,
    };
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    Cookies.set("lang", lang, { expires: 365 });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    /* const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    fetch(window.location.origin + "/fbcode", requestOptions)
      .then((response) => {
        this.setState({
          fbcomplete: true,
        });
        if (!response.ok) {
          throw new Error(response.status);
        } else {
          return response.text();
        }
      })
      .then((result) => {
        this.setState({
          fbcode: result,
        });
      })
      .catch((error) => {});*/
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (
      this.state.height > this.state.width !==
      window.innerHeight > window.innerWidth
    ) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleHover(j, i) {
    const newHover = new Array(6).fill(0);
    newHover[i] = 1;
    if (j) {
      this.setState({
        disc: newHover,
      });
    } else {
      this.setState({
        hover: newHover,
      });
    }
  }

  handleLeave(j, i) {
    const newHover = this.state.hover.slice(0);
    newHover[i] = 0;
    if (j) {
      this.setState({
        disc: newHover,
      });
    } else {
      this.setState({
        hover: newHover,
      });
    }
  }

  render() {
    if (!this.state.fbcomplete && false) {
      return null;
    } else {
      return (
        <>
          <SideBar />
          <div className="site-header">
            <div className="site-logo">
              <div className="logo-holder">
                <a href="https://staycswith.com/" className="artist-logo stayc">
                  {}
                </a>
                <a href="https://staycswith.com/" className="highup-logo">
                  {}
                </a>
              </div>
            </div>
          </div>
          <div className="site-main">
            <div className="page-cover">
              <div className="outer-margin">
                <div className="section-content">
                  <h1
                    className="headline"
                    style={{
                      color: "white",
                      fontWeight: "900",
                      fontFamily: 'Gilroy, "Noto Sans KR", sans-serif',
                    }}
                  >
                    <div className="text-mask">
                      <span>STAYC</span>
                    </div>
                    <div className="text-mask">
                      <span>SOCIAL MEDIA</span>
                    </div>
                  </h1>
                </div>
              </div>
              <div className="background">
                <div className="image landscape background-image-hor" />
                <div className="image portrait background-image-vert" />
              </div>
            </div>
            <div className="page-content">
              <div className="manifesto section active">
                <div className="outer-margin">
                  <h3
                    className="section-heading none"
                    style={{ color: "white", paddingRight: "45px" }}
                  >
                    {}
                  </h3>
                </div>
              </div>
              <div className="profile section active">
                <div className="outer-margin">
                  <h3
                    className="section-heading none"
                    style={{ fontWeight: "bold", color: "white" }}
                  >
                    {}
                  </h3>
                  <div className="section-content">
                    <div className="profile-grid">
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="STAYC_official"
                        theme="dark"
                        options={{ height: "100vh" }}
                      />
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="staycfancafe"
                        theme="dark"
                        options={{ height: "100vh" }}
                      />
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="staycjpics"
                        theme="dark"
                        options={{ height: "100vh" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer">
            <Container>
              <Row className=" align-items-center justify-content-md-between">
                <Col md="6">
                  <div className="brand-copy">STAR TO A YOUNG CULTURE</div>
                  <div className="copyright">
                    STAYC® is a registered trademark of High Up Entertainment,
                    which is not affiliated with and does not endorse this
                    website.
                  </div>
                </Col>
                <Col md="6">
                  <Nav className=" nav-footer justify-content-end">
                    <NavItem>
                      <NavLink href="/about">About</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="mailto:swithsstayc (at) gmail.com">
                        Contact
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/privacy">Privacy Policy</NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="loading-curtain" />
          <div className="nav-curtain" />
        </>
      );
    }
  }
}

export default SocialMedia;
