import React from "react";

import { Redirect } from "react-router-dom";

// reactstrap components
import { Button } from "reactstrap";

import { Table } from "react-bootstrap";
import "../../assets/css/global.css";
// core components
import SimpleFooter from "components/Footers/SimpleFooter.js";

import { Form } from "react-bootstrap";

import Cookies from "js-cookie";

import CircularProgress from "react-cssfx-loading/lib/CircularProgress";

const domain =
  process.env.REACT_APP_REL === "TRUE" ? "" : "https://staycswith.com";

class YT extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      url: "",
      audio: [],
      video: [],
      both: [],
      is_disable: false,
    };
  }

  linkChange = (event) => {
    const value = event.target.value;
    this.setState({
      url: value,
    });
  };

  click = () => {
    if (this.state.url !== "" && this.state.url.includes("youtu")) {
      this.setState({
        is_disable: true,
      });
      fetch(domain + "/yt_download?session_id=" + Cookies.get("session_id"), {
        method: "POST",
        body: JSON.stringify({
          url: this.state.url,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw response.json();
          } else {
            return response.json();
          }
        })
        .then((result) => {
          this.setState({
            audio: result.audio,
            video: result.video,
            both: result.both,
            date: result.date,
            title: result.title,
          });
        })
        .catch((error) => {})
        .finally(() => {
          this.setState({
            is_disable: false,
          });
        });
    }
  };

  render() {
    if (Cookies.get("logged_in") == null) {
      Cookies.set("return", "/yt", { expires: 1 });
      return <Redirect to="/login" />;
    }
    const audio = this.state.audio.map((row) => {
      return (
        <tr>
          <td>{row.acodec}</td>
          <td>{row.ext}</td>
          <td>{row.asr}Hz</td>
          <td>{row.filesize}</td>
          <td>
            <a href={row.url} target="_blank" rel="noopener noreferrer">
              Link
            </a>
          </td>
        </tr>
      );
    });
    const video = this.state.video.map((row) => {
      return (
        <tr>
          <td>{row.vcodec}</td>
          <td>{row.ext}</td>
          <td>{row.resolution}</td>
          <td>{row.format_note}</td>
          <td>{row.filesize}</td>
          <td>
            <a href={row.url} target="_blank" rel="noopener noreferrer">
              Link
            </a>
          </td>
        </tr>
      );
    });
    const both = this.state.both.map((row) => {
      return (
        <tr>
          <td>{row.acodec}</td>
          <td>{row.vcodec}</td>
          <td>{row.ext}</td>
          <td>{row.resolution}</td>
          <td>{row.format_note}</td>
          <td>{row.filesize}</td>
          <td>
            <a href={row.url} target="_blank" rel="noopener noreferrer">
              Link
            </a>
          </td>
        </tr>
      );
    });
    return (
      <>
        <main ref="main">
          <div className="position-relative">
            <section
              className="section"
              style={{
                minHeight: "100vh",
              }}
            >
              <div className="container">
                <h2
                  style={{
                    color: "white",
                  }}
                >
                  Yt-dlp Interface
                </h2>
                <Form
                  className="mt-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.click();
                  }}
                >
                  <Form.Group>
                    <Form.Label>Youtube Link</Form.Label>
                    <Form.Control
                      value={this.state.link}
                      onChange={this.linkChange}
                      name={"link"}
                    ></Form.Control>
                  </Form.Group>
                </Form>
                <div>
                  {this.state.audio.length === 0 ? (
                    <Button
                      className="btn-icon img-fluid"
                      color="primary"
                      onClick={this.click}
                      disabled={this.state.is_disable}
                    >
                      {this.state.is_disable ? (
                        <div>
                          Please wait{" "}
                          <CircularProgress height="20px" color="#FFFFFF" />
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  ) : (
                    <>
                      <div>Video</div>
                      <Table style={{ color: "white" }}>
                        <thead>
                          <tr>
                            <th>vcodec</th>
                            <th>ext</th>
                            <th>resolution</th>
                            <th>format_note</th>
                            <th>filesize</th>
                            <th>url</th>
                          </tr>
                        </thead>
                        <tbody>{video}</tbody>
                      </Table>
                      <div>Audio</div>
                      <Table style={{ color: "white" }}>
                        <thead>
                          <tr>
                            <th>acodec</th>
                            <th>ext</th>
                            <th>asr</th>
                            <th>filesize</th>
                            <th>url</th>
                          </tr>
                        </thead>
                        <tbody>{audio}</tbody>
                      </Table>
                      <div>Video and Audio</div>
                      <Table style={{ color: "white" }}>
                        <thead>
                          <tr>
                            <th>acodec</th>
                            <th>vcodec</th>
                            <th>ext</th>
                            <th>resolution</th>
                            <th>format_note</th>
                            <th>filesize</th>
                            <th>url</th>
                          </tr>
                        </thead>
                        <tbody>{both}</tbody>
                      </Table>
                    </>
                  )}
                </div>
              </div>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default YT;
